import { Component, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { $localize } from '@angular/localize/init';
import { LazyLoadEvent } from 'primeng/api';
import { PageChangeEvent } from '../../group-list/group-list.component';
import { InvitationDto, InvitationResponse, InvitationType } from '../../../api/dtos';
import { ButtonComponent } from '../../../shared/components/button/button.component';
import { TableDirective } from '../../../shared/components/table/table.directive';
import { TableComponent } from '../../../shared/components/table/table.component';
import { IconComponent } from '../../../shared/components/icon/icon.component';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-invitation-list',
  templateUrl: './invitation-list.component.html',
  styles: [],
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [TableComponent, TableDirective, ButtonComponent, IconComponent, DatePipe],
})
export class InvitationListComponent implements OnInit {
  @Input() invitations: InvitationDto[] = [];
  @Input() rowCount = 10;
  @Input() totalCount = 0;
  @Input() loading = false;
  @Output() removeInvitation = new EventEmitter<InvitationDto>();
  @Output() pageChange = new EventEmitter<PageChangeEvent>();

  ngOnInit(): void {}

  onRemoveInvitation(invitation: InvitationDto) {
    this.removeInvitation.emit(invitation);
  }

  onLazyLoad(event: LazyLoadEvent) {
    if (event.first !== undefined) {
      this.pageChange.emit({ offset: event.first });
    }
  }

  translate(type: InvitationType): string {
    switch (type) {
      case 'manage-group':
        return $localize`:@@permissionNames.manage-group:Manage`;
      case 'view-group':
        return $localize`:@@permissionNames.view-group:View`;
      case 'member':
        return $localize`:@@permissionNames.member:Member`;
    }
  }

  translateTypes(types: InvitationType[]): string {
    const texts = types.map(this.translate);
    return texts.join(', ');
  }

  translateResponse(response: InvitationResponse): string {
    switch (response) {
      case 'accepted':
        return $localize`:@@groupDetails.invitationsTable.response.accepted:Accepted`;
      case 'declined':
        return $localize`:@@groupDetails.invitationsTable.response.declined:Declined`;
      case 'pending':
        return $localize`:@@groupDetails.invitationsTable.response.pending:Pending`;
    }
  }
}

import { CUSTOM_ELEMENTS_SCHEMA, Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

type OnChangeFn = (searchText: string) => unknown;
type OnTouchedFn = () => unknown;

@Component({
  selector: 'app-search-field',
  template: `<syn-input
    [attr.value]="searchText"
    [attr.disabled]="isDisabled ? true : null"
    [attr.placeholder]="placeholder"
    [attr.label]="label"
    clearable="true"
    (syn-input)="onInput($event)"
    (syn-blur)="onBlur()"
  >
    <syn-icon name="search" slot="prefix"></syn-icon>
  </syn-input> `,
  styles: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SearchFieldComponent,
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  standalone: true,
})
export class SearchFieldComponent implements ControlValueAccessor {
  @Input() placeholder = '';
  @Input() label = '';
  @Output() textChange = new EventEmitter<string>();

  searchText = '';
  isDisabled = false;

  private isTouched = false;
  private onChange: OnChangeFn = (_searchText: string) => {};
  private onTouched: OnTouchedFn = () => {};

  writeValue(searchText: string) {
    this.searchText = searchText;
  }

  registerOnChange(onChange: OnChangeFn): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: OnTouchedFn): void {
    this.onTouched = onTouched;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onInput(event: Event) {
    const newValue = (event.target as HTMLInputElement).value;
    if (typeof newValue === 'string') {
      this.searchText = newValue;
      this.onChange(newValue);
      this.textChange.emit(newValue);
    }
  }

  onBlur() {
    if (this.isTouched) return;
    this.onTouched();
    this.isTouched = true;
  }

  clear() {
    this.searchText = '';
  }
}

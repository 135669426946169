<form [formGroup]="form" (ngSubmit)="onSubmit('group-details')">
  <div class="form-body">
    @if (customerName) {
      <div class="label" for="customer-name" i18n="@@groupPropertiesForm.customer">Customer Name</div>
      <app-text-field id="customer-name" [text]="customerName" [disabled]="true"></app-text-field>
    }
    <div class="label" for="name" i18n="@@groupPropertiesForm.name">Group Name</div>
    <app-text-field id="name" type="text" formControlName="name" [error]="hasError('name') ? getNameErrorText() : null">
    </app-text-field>
    <div class="label" for="description" i18n="@@groupPropertiesForm.description">Description</div>
    <app-text-area id="description" formControlName="description" [error]="getDescriptionErrorText()"> </app-text-area>
    <div class="label" for="group-visibility" i18n="@@groupPropertiesForm.visibility">Visibility</div>
    <div class="visibility-row">
      <app-toggle-switch
        class="toggle"
        id="visibility"
        formControlName="hasCompanyVisibility"
        iconOn="visibility"
        iconOff="visibility_off"
      >
      </app-toggle-switch>
      <span class="small-info-text">
        {{ getVisibilityInfo() }}
      </span>
    </div>
    @if (id.length > 0) {
      <div class="label" i18n="@@groupDetails.details.id">ID</div>
      <span> {{ id }} </span>
    }
  </div>
  @if (submitButton) {
    <div class="button-row">
      @if (cancelButton) {
        <app-button data-testid="cancel" type="outline" [routerLink]="'..'" i18n="@@cancel">Cancel</app-button>
      }
      <app-button data-testid="submit" (submit)="onSubmit('group-details')" [disabled]="submitButtonDisabled">
        {{ submitButton }}
      </app-button>
    </div>
  }
</form>

import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';

import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';

import '@synergy-design-system/components/components/button/button.js';
import '@synergy-design-system/components/components/input/input.js';
import '@synergy-design-system/components/components/icon-button/icon-button.js';
import '@synergy-design-system/components/components/spinner/spinner.js';
import '@synergy-design-system/components/components/alert/alert.js';
import '@synergy-design-system/components/components/header/header.js';
import '@synergy-design-system/components/components/icon/icon.js';
import '@synergy-design-system/components/components/dialog/dialog.js';
import '@synergy-design-system/components/components/radio-group/radio-group.js';
import '@synergy-design-system/components/components/radio/radio.js';
import '@synergy-design-system/components/components/textarea/textarea.js';
import '@synergy-design-system/components/components/switch/switch.js';
import '@synergy-design-system/components/components/tab-group/tab-group.js';
import '@synergy-design-system/components/components/tab-panel/tab-panel.js';
import '@synergy-design-system/components/components/tab/tab.js';
import '@synergy-design-system/components/components/select/select.js';
import '@synergy-design-system/components/components/option/option.js';
import '@synergy-design-system/components/components/menu/menu.js';
import '@synergy-design-system/components/components/menu-item/menu-item.js';
import '@synergy-design-system/components/components/dropdown/dropdown.js';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, appConfig).catch((err) => console.error(err));

import { Directive, Input } from '@angular/core';
import { TemplateDirective } from '../../directives/template.directive';

export type TableTemplateType = 'header' | 'body' | 'footer';

@Directive({
  selector: '[tableTemplate]',
  standalone: true,
})
export class TableDirective extends TemplateDirective {
  @Input() tableTemplate: TableTemplateType = 'body';

  get type(): TableTemplateType {
    return this.tableTemplate;
  }
}

import { Injectable, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';

@Injectable()
export class I18nTitleStrategy extends TitleStrategy {
  private readonly title = inject(Title);

  override updateTitle(snapshot: RouterStateSnapshot): void {
    const title = super.buildTitle(snapshot);
    const fallback = $localize`:@@routes.fallbackTitle:Group Management`;
    this.title.setTitle(title ?? fallback);
  }
}

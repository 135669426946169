<ng-container>
  @if (invalidLink) {
    <app-inline-notification [variant]="'danger'" i18n="@@invitation.invalidLink">
      The invitation link is invalid or incomplete. Make sure that you clicked the invitation link from the mail or
      copied it completely.
    </app-inline-notification>
  } @else {
    @if (viewModel$ | async; as viewModel) {
      <div class="center">
        @if (viewModel.response === 'pending') {
          <div>
            <h2>
              {{ getText(viewModel.groupName, viewModel.sender) }}
            </h2>
            <div class="text-content">
              @if (viewModel.freeText.length > 0) {
                <div>
                  <p i18n="@@invitation.freeTextIntro">
                    The sender of this invitation has provided additional information:
                  </p>
                  <p></p>
                  <p>{{ viewModel.freeText }}</p>
                </div>
              }
              <div>
                <span i18n="@@invitation.joinIntro">By accepting this invitation you will</span>
                <ul>
                  @if (viewModel.isMember) {
                    <li i18n="@@invitation.becomeMember">become a member of the group</li>
                  }
                  @if (viewModel.isManager) {
                    <li i18n="invitation.becomeManager">become a manager of the group</li>
                  }
                  @if (viewModel.isViewer) {
                    <li i18n="@@invitation.becomeViewer">be able to view the group</li>
                  }
                </ul>
              </div>
            </div>
            @if (!viewModel.sendingResponse) {
              <div class="buttons">
                <app-button
                  [disabled]="viewModel.sendingResponse"
                  (submit)="onAccept()"
                  i18n="@@invitation.acceptInvitation"
                >
                  Accept Invitation
                </app-button>
                <app-button
                  [type]="'outline'"
                  [disabled]="viewModel.sendingResponse"
                  (submit)="onDecline()"
                  i18n="@@invitation.declineInvitation"
                >
                  Decline Invitation
                </app-button>
              </div>
            } @else {
              <app-spinner i18n-label="@@invitation.sendingMessage" label="Sending response..."></app-spinner>
            }
          </div>
        }
        @if (viewModel.response === 'accepted') {
          <div>
            <h2 i18n="@@invitation.alreadyAccepted.title">Invitation already accepted</h2>
            <div class="text-content">
              <p>{{ getAlreadyAcceptedExplanation(viewModel.groupName) }}</p>
            </div>
            <div class="buttons">
              <app-button
                [routerLink]="['/my-groups', viewModel.groupId]"
                i18n="@@invitation.alreadyAccepted.navigateToGroup"
              >
                Go to Group Details
              </app-button>
            </div>
          </div>
        }
        @if (viewModel.response === 'declined') {
          <div>
            <h2 i18n="@@invitation.alreadyDeclined.title">Invitation already declined</h2>
            <div class="text-content">
              <p>{{ getAlreadyDeclinedExplanation(viewModel.groupName) }}</p>
            </div>
            <div class="buttons">
              <app-button [routerLink]="'/my-groups/'" i18n="@@invitation.alreadyDeclined.navigateToHome">
                Go to My Groups
              </app-button>
            </div>
          </div>
        }
      </div>
    }
  }
</ng-container>

import { CUSTOM_ELEMENTS_SCHEMA, Component, Input } from '@angular/core';

@Component({
  selector: 'app-icon',
  template: `<div class="flex-row justify-center">
    <!-- The additional <div> ensures vertical alignment of the icon. -->
    <!-- See the invitations table where text and icon need to be aligned. -->
    <syn-icon [attr.name]="icon"></syn-icon>
  </div>`,
  styles: [
    `
      syn-icon {
        font-size: var(--syn-font-size-large);
      }
    `,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  standalone: true,
})
export class IconComponent {
  @Input() icon?: string;
}

<app-delete-group-dialog
  [groupName]="groupToDelete?.display_name ?? ''"
  (canceled)="closeDeleteModal()"
  (confirmed)="onDeleteGroupConfirmed()"
>
</app-delete-group-dialog>

<app-table
  [values]="groups"
  [rowsPerPage]="rowCount"
  [totalValuesCount]="totalCount"
  (onLazyLoad)="onLazyLoad($event)"
  [hoverable]="true"
  [loading]="loading"
  [first]="offset"
>
  <ng-template tableTemplate="header">
    <tr>
      <th style="min-width: 13rem; white-space: nowrap" i18n="@@groupList.name">Group Name</th>
      <th style="width: 6rem" i18n="@@groupList.visibility">Visibility</th>
      <!-- Placeholder to move the visibility to the left -->
      <th></th>
      @if (showDeleteButton) {
        <th style="width: 4rem" i18n="@@remove">Remove</th>
      }
    </tr>
  </ng-template>
  <ng-template tableTemplate="body" let-group>
    <tr
      [routerLink]="['/', baseLink, group.id]"
      i18n-title="@@groupList.detailsTooltip"
      title="Click to see group details"
    >
      <td style="white-space: nowrap">{{ group.display_name }}</td>
      <td class="td-center">
        <app-icon
          [icon]="getVisiblityIcon(group.visibility)"
          [title]="getVisibilityTooltip(group.visibility)"
        ></app-icon>
      </td>
      <td style="width: 100%" data-testid="details"></td>
      @if (showDeleteButton) {
        <td class="td-center" (click)="preventEventPropagation($event)">
          <syn-icon-button
            name="delete"
            size="medium"
            data-testid="delete"
            i18n-label="@@remove"
            label="Remove"
            (click)="onDeleteButtonClicked(group)"
          ></syn-icon-button>
        </td>
      }
    </tr>
  </ng-template>
</app-table>

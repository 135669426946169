import { CUSTOM_ELEMENTS_SCHEMA, Component } from '@angular/core';

@Component({
  selector: 'app-spinner',
  template: ` <syn-spinner id="spinner"></syn-spinner> `,
  styles: [``],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  standalone: true,
})
export class SpinnerComponent {}

import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { ActionCreator, Creator, Store, Action } from '@ngrx/store';
import { map, mergeMap, Observable, of, tap } from 'rxjs';
import { GroupDetailsParameters } from '../../../api/http';
import { GroupId } from '../../model';
import { selectGroupDetails, selectGroups } from '../groups.feature';
import { hasPage, Pages } from '../groups.pagination';
import {
  editGroupActions,
  groupDetailsActions,
  GroupDetailsSlice,
  invitationListActions,
  memberListActions,
  permissionListActions,
} from './group-details.slice';
import { groupApiActions } from '../../../api/actions';
import { GroupApi } from '../../../api/group';

function makeGroupDetailsParameters(details: GroupDetailsSlice | undefined): GroupDetailsParameters {
  if (!details) {
    throw new Error('A group must be selected to show group details');
  }
  const members = details.memberPages;
  const permissions = details.permissionPages;
  const invitations = details.invitationPages;

  return {
    membersOffset: members.offset,
    membersMax: members.pageLength,
    permissionsOffset: permissions.offset,
    permissionsMax: permissions.pageLength,
    invitationsOffset: invitations.offset,
    invitationsMax: invitations.pageLength,
  };
}

/**
 * These effects are mostly responsible for handling UI events that trigger
 * an API call to the group management backend.
 *
 * Example:
 *  - The user updated a group's properties and clicked the 'Save' button.
 *  - The editGroupActions.updateGroupProperties action is fired
 *  - The updateGroup$ effect calls the API function to update the group
 *  - The groupApiActions.groupUpdated action is fired on successful update
 *  - A reducer in groups.feature.ts will update the state in the store
 */
@Injectable()
export class GroupDetailEffects {
  private actions$ = inject(Actions);
  private store = inject(Store);
  private api = inject(GroupApi);
  private router = inject(Router);

  loadGroupDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        groupDetailsActions.pageInitialized,
        editGroupActions.pageInitialized,
        // We load all group details, since adding a member might have changed permissions
        // This avoids two simultaneous HTTP request
        // Although, we do not need all the data from that request.
        groupApiActions.invitationsCreated,
        groupApiActions.membersRemoved
      ),
      concatLatestFrom(() => [this.store.select(selectGroupDetails)]),
      mergeMap(([{ groupId }, details]) => {
        const params = makeGroupDetailsParameters(details);
        return this.api.loadGroupDetails(groupId, params);
      })
    )
  );

  loadGroupIfNotPresent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(editGroupActions.propertiesPageInitialized),
      concatLatestFrom(() => [this.store.select(selectGroups)]),
      mergeMap(([{ groupId }, groups]) => this.api.loadGroupIfNotPresent(groupId, groups))
    )
  );

  navigateToEditGroupProperties$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(editGroupActions.editGroupProperties),
        tap(({ groupId }) => {
          return this.router.navigate(['managed-groups', groupId, 'edit']);
        })
      ),
    { dispatch: false }
  );

  filterMembers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(memberListActions.filterChanged),
      concatLatestFrom(() => [this.store.select(selectGroupDetails)]),
      mergeMap(([{ groupId, text }, details]) => {
        const pageLength = details?.memberPages.pageLength || 10;
        return this.api.loadMembers(groupId, 0, pageLength, text);
      })
    )
  );

  filterPermissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(permissionListActions.filterChanged),
      concatLatestFrom(() => [this.store.select(selectGroupDetails)]),
      mergeMap(([{ groupId, text }, details]) => {
        const pageLength = details?.permissionPages.pageLength || 10;
        return this.api.loadPermissions(groupId, 0, pageLength, text);
      })
    )
  );

  loadMembersAfterOffsetChanged$ = this.createOffsetChangedEffect({
    pagesSelector: (details) => details.memberPages,
    observedAction: memberListActions.offsetChanged,
    pageExistsAction: memberListActions.pageAlreadyLoaded,
    onPageDoesNotExist: this.api.loadMembers.bind(this.api),
  });

  invalidatePermissions$ = this.createInvalidatePagesEffect({
    pagesSelector: (details) => details.permissionPages,
    observedActions: [groupApiActions.permissionsGranted, groupApiActions.permissionsChanged],
    offsetChangedAction: permissionListActions.offsetChanged,
  });

  loadPermissionsAfterOffsetChanged$ = this.createOffsetChangedEffect({
    pagesSelector: (details) => details.permissionPages,
    observedAction: permissionListActions.offsetChanged,
    pageExistsAction: permissionListActions.pageAlreadyLoaded,
    onPageDoesNotExist: this.api.loadPermissions.bind(this.api),
  });

  invalidateInvitations$ = this.createInvalidatePagesEffect({
    pagesSelector: (details) => details.invitationPages,
    observedActions: [groupApiActions.invitationDeleted],
    offsetChangedAction: invitationListActions.offsetChanged,
  });

  loadInvitationsAfterOffsetChanged$ = this.createOffsetChangedEffect({
    pagesSelector: (details) => details.invitationPages,
    observedAction: invitationListActions.offsetChanged,
    pageExistsAction: invitationListActions.pageAlreadyLoaded,
    onPageDoesNotExist: this.api.loadInvitations.bind(this.api),
  });

  addMembers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(editGroupActions.addMembers),
      mergeMap(({ groupId, userIds }) => this.api.addMembers(groupId, userIds))
    )
  );

  addAndInviteMembers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(editGroupActions.addAndInviteMembers),
      mergeMap(({ groupId, addUserIds, invite }) => this.api.addAndInviteMembers(groupId, addUserIds, invite))
    )
  );

  removeMembers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(editGroupActions.removeMembers),
      mergeMap(({ groupId, userIds }) => this.api.removeMembers(groupId, userIds))
    )
  );

  changePermissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(editGroupActions.changePermission),
      mergeMap(({ groupId, change, grant }) => this.api.changePermissions(groupId, change, grant))
    )
  );

  updateGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(editGroupActions.updateGroupProperties),
      mergeMap(({ groupId, properties }) => this.api.updateGroup(groupId, properties))
    )
  );

  createInvitations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(editGroupActions.createInvitations, groupApiActions.membersAdded),
      mergeMap(({ groupId, invite }) => this.api.createInvitations(groupId, invite))
    )
  );

  deleteInvitation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(editGroupActions.deleteInvitations),
      mergeMap(({ groupId, code }) => this.api.deleteInvitation(groupId, code))
    )
  );

  private createOffsetChangedEffect<T>({
    pagesSelector,
    observedAction,
    pageExistsAction,
    onPageDoesNotExist,
  }: OffsetChangedEffectConfiguration<T>) {
    return createEffect(() =>
      this.actions$.pipe(
        ofType(observedAction),
        concatLatestFrom(() => [this.store.select(selectGroupDetails)]),
        mergeMap(([{ offset, invalidatePages }, details]) => {
          if (!details) {
            throw new Error('A group must be selected to display detail pages.');
          }
          if (!invalidatePages && hasPage(pagesSelector(details).pages, offset)) {
            return of(pageExistsAction());
          }
          const pages = pagesSelector(details);
          return onPageDoesNotExist(details.id, offset, pages.pageLength, pages.filter, invalidatePages);
        })
      )
    );
  }

  private createInvalidatePagesEffect<T>({
    pagesSelector,
    observedActions,
    offsetChangedAction,
  }: InvalidatePagesEffectConfiguration<T>) {
    return createEffect(() =>
      this.actions$.pipe(
        ofType(...observedActions),
        concatLatestFrom(() => [this.store.select(selectGroupDetails)]),
        map(([_, details]) => {
          const offset = details ? pagesSelector(details).offset : 0;
          return offsetChangedAction(offset, true);
        })
      )
    );
  }
}

type PagesSelector<T> = (details: GroupDetailsSlice) => Pages<T>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TypedActionCreator<Payload> = ActionCreator<string, Creator<any[], Payload & Action<string>>>;
type OffsetActionCreator = TypedActionCreator<{ offset: number; invalidatePages?: boolean }>;
type EmptyActionCreator = TypedActionCreator<object>;
type OnPageDataRequestedFunction = (
  groupId: GroupId,
  offset: number,
  pageLength: number,
  filter: string,
  invalidatePages?: boolean
) => Observable<Action>;

interface OffsetChangedEffectConfiguration<T> {
  pagesSelector: PagesSelector<T>;
  observedAction: OffsetActionCreator;
  pageExistsAction: EmptyActionCreator;
  onPageDoesNotExist: OnPageDataRequestedFunction;
}
interface InvalidatePagesEffectConfiguration<T> {
  pagesSelector: PagesSelector<T>;
  observedActions: EmptyActionCreator[];
  offsetChangedAction: OffsetActionCreator;
}

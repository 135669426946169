import { GroupMemberDto, InvitationDto, PageDto, PermissionDto, UserDto } from '../../api/dtos';
import { UserMap, mapToGroupUser } from '../model';
import { GroupDetailsSlice } from './group-details/group-details.slice';
import { GroupsState } from './groups.feature';

export type PagesName = 'memberPages' | 'invitationPages' | 'permissionPages';

export interface PageLoadedEvent<T> {
  page: PageDto<T>;
  offset: number;
  pageLength: number;
  invalidatePages: boolean;
}
export interface PageCache<T> {
  [key: number]: T[] | undefined;
}

export interface Pages<T> {
  offset: number;
  pageLength: number;
  totalCount: number;
  // How many entries are in the table
  // If no filter is applied, this equals the total count
  // If a filter is applied, this equals the filtered entries count
  tableCount: number;
  // Filter text, empty string means no filter should be applied
  filter: string;
  loading: boolean;
  pages: PageCache<T>;
}

export type GroupMemberPages = Pages<GroupMemberDto>;
export type GroupPermissionPages = Pages<PermissionDto>;
export type InvitationPages = Pages<InvitationDto>;

export function emptyPages<EntryT>(): Pages<EntryT> {
  return {
    offset: 0,
    pageLength: 10,
    pages: {},
    totalCount: 0,
    tableCount: 0,
    filter: '',
    // Initially, all pages are in the loading state
    // After the page has been returned from the API, loading is set to false
    loading: true,
  };
}

export function mergePages<T>(state: GroupsState, event: PageLoadedEvent<T>, name: PagesName): GroupsState {
  const details = state.groupDetails;
  if (!details) {
    console.log('Discarding page loaded action since no group details are present');
    return state;
  }

  let pages: PageCache<T> = { [event.offset]: event.page.page };
  if (!event.invalidatePages) {
    const oldPages = details[name].pages as PageCache<T>;
    pages = { ...oldPages, ...pages };
  }

  return {
    ...state,
    users: mergeUsers(state.users, event.page.users),
    groupDetails: {
      ...details,
      [name]: {
        totalCount: event.page.totalCount,
        tableCount: event.page.totalCount,
        offset: event.offset,
        pageLength: event.pageLength,
        filter: '',
        pages,
        loading: false,
      },
    },
  };
}

export function mergeUsers(users: UserMap, newUsers: UserDto[]) {
  const merged = { ...users };
  for (const newUser of newUsers) {
    merged[newUser.id] = mapToGroupUser(newUser);
  }
  return merged;
}

export function updatePage<Dto>(
  state: GroupsState,
  page: PageDto<Dto> | null,
  users: UserDto[],
  pagesName: PagesName
): GroupsState {
  if (page && state.groupDetails) {
    const pages = state.groupDetails[pagesName];
    const event: PageLoadedEvent<Dto> = {
      invalidatePages: true,
      offset: 0,
      pageLength: pages.pageLength,
      page: { ...page, users: users },
    };
    return mergePages(state, event, pagesName);
  } else {
    return state;
  }
}

export function setOffset(state: GroupsState, key: keyof GroupDetailsSlice, offset: number): GroupsState {
  const details = state.groupDetails;
  if (!details) {
    return state;
  }
  return {
    ...state,
    groupDetails: {
      ...details,
      [key]: {
        ...(details[key] as object),
        offset: offset,
        loading: true,
      },
    },
  };
}

export function hasPage<T>(pages: PageCache<T>, offset: number) {
  return Object.prototype.hasOwnProperty.call(pages, offset);
}

export function setLoadingState(state: GroupsState, pagesName: PagesName, loading = false): GroupsState {
  if (state.groupDetails?.[pagesName]) {
    return {
      ...state,
      groupDetails: {
        ...state.groupDetails,
        [pagesName]: {
          ...state.groupDetails[pagesName],
          loading,
        },
      },
    };
  }
  return { ...state };
}

import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { createActionGroup, createFeature, createReducer, createSelector, emptyProps, on } from '@ngrx/store';
import { tap } from 'rxjs';
import { AuthUser } from '../types';
import { keycloak } from '../auth';

export interface AuthState {
  user: AuthUser | undefined;
  isAuthenticated: boolean;
}

export const initialState: AuthState = {
  user: undefined,
  isAuthenticated: false,
};

export const authActions = createActionGroup({
  source: 'Auth Service',
  events: {
    'User Login': (userData: AuthUser) => ({ userData }),
    'Logout Requested': emptyProps(),
  },
});

export const authFeature = createFeature({
  name: 'auth',
  reducer: createReducer(
    initialState,
    on(
      authActions.userLogin,
      (state, { userData }): AuthState => ({
        ...state,
        isAuthenticated: true,
        user: userData,
      })
    )
  ),
});

export const { name, reducer, selectAuthState, selectIsAuthenticated, selectUser } = authFeature;

export const selectUserName = createSelector(selectUser, (user) => user?.fullName);

@Injectable()
export class AuthEffects {
  private actions$ = inject(Actions);

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.logoutRequested),
        tap(() => {
          // Without subscribing, the logout is not executed
          return keycloak.logout();
          // this.oidcSecurityService.logoff().subscribe(() => {});
        })
      ),
    { dispatch: false }
  );
}

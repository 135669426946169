import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ButtonComponent } from '../../../shared/components/button/button.component';
import { DialogComponent } from '../../../shared/components/dialog/dialog.component';

@Component({
  selector: 'app-delete-group-dialog',
  template: `<app-dialog [header]="getHeader()">
    <p>
      <span i18n="@@groupList.deleteModal.warning">Deleting the group is permanent and cannot be reversed.</span>
      <br />
      <span i18n="@@groupList.deleteModal.proceedQuestion">Are you sure, you want to proceed?</span>
    </p>

    <div class="button-cell" slot="footer">
      <app-button (submit)="onCancel()" type="outline" i18n="@@cancel">Cancel</app-button>
      <app-button
        (submit)="onConfirmed()"
        type="filled"
        data-testid="confirm"
        i18n="@@groupList.deleteModal.deleteGroupPermanently"
      >
        Delete Group Permanently
      </app-button>
    </div>
  </app-dialog>`,
  styles: [
    `
      .button-cell {
        display: flex;
        gap: 16px;
        justify-content: right;
      }

      @media (max-width: 640px) {
        .button-cell {
          flex-direction: column;
        }
      }
    `,
  ],
  standalone: true,
  imports: [DialogComponent, ButtonComponent],
})
export class DeleteGroupDialogComponent {
  @Input() groupName = '';
  @Output() canceled = new EventEmitter();
  @Output() confirmed = new EventEmitter();

  @ViewChild(DialogComponent) dialog!: DialogComponent;

  public show(): void {
    this.dialog.show();
  }

  public hide(): void {
    this.dialog.hide();
  }

  getHeader() {
    return $localize`:@@groupList.deleteModal.header:Delete Group ${this.groupName}`;
  }

  onCancel() {
    this.dialog.hide();
    this.canceled.emit();
  }

  onConfirmed() {
    this.dialog.hide();
    this.confirmed.emit();
  }
}

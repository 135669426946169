import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from '../../environments/environment';
import {
  AppliedMemberChangesDto,
  AppliedPermissionChangesDto,
  FilteredUsersDto,
  GroupDetailsDto,
  GroupDto,
  GroupInputDto,
  GroupMemberPageDto,
  GroupPageDto,
  GroupRelation,
  GroupsOverviewDto,
  GroupsOverviewParameters,
  InvitationDto,
  InvitationInputListDto,
  InvitationPageDto,
  InvitationResponseOption,
  InvitationWithSenderInfoDto,
  MemberChangeDto,
  PermissionChangeDto,
  PermissionName,
  PermissionPageDto,
  SendInvitationResultsDto,
  SupportRequestDto,
  UserDto,
  UserFilterType,
  UserSearchResultDto,
} from './dtos';

export const apiEndpoints = {
  groups: `${environment.apiUrl}/groups`,
  users: `${environment.apiUrl}/users`,
  support: `${environment.apiUrl}/support`,
};

interface PaginationParameters {
  offset?: number;
  pageLength?: number;
}

export interface GroupParameters extends PaginationParameters {
  userId?: string;
  search?: string;
  relation?: GroupRelation;
}

export interface MemberParameters extends PaginationParameters {
  groupId: string;
}

export interface PermissionParameters extends PaginationParameters {
  groupId: string;
  permissionType?: PermissionName;
}

export interface ManagerParameters extends PaginationParameters {
  groupId: string;
}

export interface InvitationParamters extends PaginationParameters {
  groupId: string;
}

export interface GroupDetailsParameters {
  membersOffset: number;
  membersMax: number;
  permissionsOffset: number;
  permissionsMax: number;
  invitationsOffset: number;
  invitationsMax: number;
}

const headers = new HttpHeaders({
  'Content-Type': 'application/json',
  Accept: 'application/json',
});

@Injectable({
  providedIn: 'root',
})
export class GroupHttpApi {
  private http = inject(HttpClient);

  getGroupsOverview(parameters: GroupsOverviewParameters): Observable<GroupsOverviewDto> {
    const params = createQueryParams({ ...parameters });

    return this.http.get<GroupsOverviewDto>(`${apiEndpoints.groups}/overview`, { params, headers });
  }

  getGroups({ userId, search: searchText, offset, pageLength, relation }: GroupParameters): Observable<GroupPageDto> {
    const paramOptions = {
      search: searchText,
      offset: offset,
      max: pageLength,
      ...mapGroupRelationToQueryParam(userId, relation),
    };

    const params = createQueryParams(paramOptions);

    return this.http.get<GroupPageDto>(apiEndpoints.groups, { params, headers });
  }

  getGroupById(groupId: string) {
    return this.http.get<GroupDto>(`${apiEndpoints.groups}/${groupId}`, { headers });
  }

  getGroupDetails(groupId: string, parameters: GroupDetailsParameters) {
    const params = createQueryParams({ ...parameters });
    return this.http.get<GroupDetailsDto>(`${apiEndpoints.groups}/${groupId}/details`, { params, headers });
  }

  getMembers(params: MemberParameters): Observable<GroupMemberPageDto> {
    const queryParams = createQueryParams({
      offset: params.offset,
      max: params.pageLength,
    });
    return this.http.get<GroupMemberPageDto>(`${apiEndpoints.groups}/${params.groupId}/members`, {
      params: queryParams,
      headers,
    });
  }

  searchGroupUsers(groupId: string, type: UserFilterType, text: string): Observable<FilteredUsersDto> {
    const params = createQueryParams({
      type,
      search: text,
    });
    return this.http.get<FilteredUsersDto>(`${apiEndpoints.groups}/${groupId}/users`, { params, headers });
  }

  createGroup(input: GroupInputDto) {
    return this.http.post<GroupDto>(`${apiEndpoints.groups}`, input, { headers });
  }

  updateGroup(groupId: string, input: GroupInputDto) {
    return this.http.put<GroupDto>(`${apiEndpoints.groups}/${groupId}`, input, { headers });
  }

  deleteGroup(groupId: string) {
    return this.http.delete(`${apiEndpoints.groups}/${groupId}`, { headers });
  }

  changeMembers(groupId: string, change: MemberChangeDto) {
    return this.http.post<AppliedMemberChangesDto>(`${apiEndpoints.groups}/${groupId}/members`, change, { headers });
  }

  changePermissions(groupId: string, change: PermissionChangeDto) {
    return this.http.post<AppliedPermissionChangesDto>(`${apiEndpoints.groups}/${groupId}/permissions`, change, {
      headers,
    });
  }

  getPermissions(params: PermissionParameters) {
    const queryParams = createQueryParams({
      offset: params.offset,
      max: params.pageLength,
      name: params.permissionType,
    });
    return this.http.get<PermissionPageDto>(`${apiEndpoints.groups}/${params.groupId}/permissions`, {
      params: queryParams,
      headers,
    });
  }

  searchUsers(searchText: string, maxResults: number): Observable<UserDto[]> {
    return this.http
      .get<UserSearchResultDto>(`${apiEndpoints.users}`, {
        params: {
          search: searchText,
          max: maxResults,
        },
        headers,
      })
      .pipe(map((result) => result.users));
  }

  createInvitations(groupId: string, input: InvitationInputListDto): Observable<SendInvitationResultsDto> {
    return this.http.post<SendInvitationResultsDto>(`${apiEndpoints.groups}/${groupId}/invitations`, input, {
      headers,
    });
  }

  getInvitations(params: InvitationParamters) {
    const queryParams = createQueryParams({
      offset: params.offset,
      max: params.pageLength,
    });
    return this.http.get<InvitationPageDto>(`${apiEndpoints.groups}/${params.groupId}/invitations`, {
      params: queryParams,
      headers,
    });
  }

  getInvitation(groupId: string, code: string, link: string) {
    return this.http.get<InvitationWithSenderInfoDto>(`${apiEndpoints.groups}/${groupId}/invitations/${code}`, {
      params: { link },
      headers,
    });
  }

  deleteInvitation(groupId: string, code: string) {
    return this.http.delete(`${apiEndpoints.groups}/${groupId}/invitations/${code}`, { headers });
  }

  postInvitationResponse(groupId: string, code: string, response: InvitationResponseOption) {
    return this.http.post<InvitationDto>(
      `${apiEndpoints.groups}/${groupId}/invitations/${code}/response`,
      {
        response,
      },
      { headers }
    );
  }

  createSupportRequest(supportRequest: SupportRequestDto): Observable<null> {
    return this.http.post<null>(apiEndpoints.support, supportRequest, { headers });
  }
}

function mapGroupRelationToQueryParam(userId?: string, relation?: GroupRelation) {
  switch (relation) {
    case 'manager':
      return { manager: userId };
    case 'visible':
      return {};
    default:
      return { member: userId };
  }
}

function createQueryParams(params: Record<string, string | number | boolean | undefined>): HttpParams {
  let result = new HttpParams();
  for (const key in params) {
    const value = params[key];
    if (value) {
      // The append method does not change the object, but returns a new object!
      result = result.append(key, value);
    }
  }
  return result;
}

import { createActionGroup } from '@ngrx/store';
import {
  AppliedMemberChangesDto,
  AppliedPermissionChangesDto,
  FilteredUsersDto,
  GroupDetailsDto,
  GroupDto,
  GroupMemberPageDto,
  GroupPageDto,
  GroupRelation,
  GroupsOverviewDto,
  InvitationDto,
  InvitationInputListDto,
  InvitationPageDto,
  InvitationWithSenderInfoDto,
  PermissionPageDto,
  SendInvitationResultsDto,
} from './dtos';
import { GroupId, NavigationTarget } from '../groups/model';

export const groupApiActions = createActionGroup({
  source: 'Group API',
  events: {
    'Overview Loaded': (result: GroupsOverviewDto, search: boolean) => ({ result, search }),
    'Group Page Loaded': (page: GroupPageDto, offset: number, relation: GroupRelation, invalidatePages?: boolean) => ({
      page,
      offset,
      relation,
      invalidatePages: invalidatePages ?? false,
    }),
    'Group Created': (id: GroupId, target: NavigationTarget) => ({ id, target }),
    'Group Deleted': (id: GroupId) => ({ id }),
    'Group Loaded': (group: GroupDto) => ({ group }),
    'Group Updated': (group: GroupDto) => ({ group }),
    'Group Details Loaded': (result: GroupDetailsDto) => ({ result }),
    'Members Loaded': (page: GroupMemberPageDto, offset: number, pageLength: number, invalidatePages: boolean) => ({
      page,
      offset,
      pageLength,
      invalidatePages,
    }),
    'Members Filtered': (groupId: string, text: string, result: FilteredUsersDto) => ({ groupId, text, result }),
    'Members Added': (groupId: string, result: AppliedMemberChangesDto, invite: InvitationInputListDto) => ({
      groupId,
      result,
      invite,
    }),
    'Members Removed': (groupId: string, result: AppliedMemberChangesDto) => ({
      groupId,
      result,
    }),
    'Permissions Granted': (groupId: string, result: AppliedPermissionChangesDto) => ({
      groupId,
      result,
    }),
    'Permissions Changed': (groupId: string, result: AppliedPermissionChangesDto) => ({
      groupId,
      result,
    }),
    'Permissions Loaded': (page: PermissionPageDto, offset: number, pageLength: number, invalidatePages: boolean) => ({
      page,
      offset,
      pageLength,
      invalidatePages,
    }),
    'Permissions Filtered': (groupId: string, text: string, result: FilteredUsersDto) => ({ groupId, text, result }),
    'Invitations Created': (groupId: string, result: SendInvitationResultsDto) => ({
      groupId,
      result,
    }),
    'Invitations Loaded': (page: InvitationPageDto, offset: number, pageLength: number, invalidatePages: boolean) => ({
      page,
      offset,
      pageLength,
      invalidatePages,
    }),
    'Invitation Loaded': (result: InvitationWithSenderInfoDto) => ({ result }),
    'Invitation Deleted': (groupId: string, code: string) => ({ groupId, code }),
    'Invitation Response Updated': (result: InvitationDto) => ({ result }),
  },
});

<app-table
  [values]="permissions"
  [totalValuesCount]="totalCount"
  [rowsPerPage]="rowCount"
  [loading]="loading"
  (onLazyLoad)="onLazyLoad($event)"
>
  <ng-template tableTemplate="header">
    <tr>
      <th i18n="@@groupDetails.permissionsTable.name">Name</th>
      <th i18n="@@groupDetails.permissionsTable.email">Email</th>
      <th i18n="@@groupDetails.permissionsTable.permission">Permission</th>
      <th class="min" i18n="@@groupDetails.permissionsTable.revokeAllPermissions">Revoke All Permissions</th>
    </tr>
  </ng-template>
  <ng-template tableTemplate="body" let-permission>
    <tr>
      @if (permission.user.name) {
        <td>
          {{ permission.user.name + (permission.user.username ? ' (' + permission.user.username + ')' : '') }}
        </td>
      } @else {
        <td>
          <p class="external-user" i18n="@@groupDetails.externalUser">External User</p>
        </td>
      }
      <td>{{ permission.user.email ?? '-' }}</td>
      <td>
        <span [title]="getTitleText(permission, texts.change)">
          <app-permission-dropdown
            [value]="dropdownValueOf(permission.permissions)"
            (valueChange)="onPermissionChange($event, permission)"
            [editable]="isEditable(permission)"
          >
          </app-permission-dropdown>
        </span>
      </td>
      <td class="td-center">
        <syn-icon-button
          name="delete"
          size="medium"
          [title]="getTitleText(permission, texts.revokeAll)"
          [disabled]="!isEditable(permission)"
          i18n-label="@@remove"
          label="Remove"
          (click)="onRevokeAllPermission(permission)"
        ></syn-icon-button>
      </td>
    </tr>
  </ng-template>
</app-table>

import { GroupInputDto, InvitationType, PermissionName, UserDto } from '../api/dtos';

export type GroupId = string;
export type UserId = string;

export type NavigationTarget = 'group-details' | 'managed-groups';

export interface GroupPropertiesSubmitEvent {
  properties: GroupInputDto;
  target: NavigationTarget;
}

export type GroupVisibility = 'company' | 'private';

export type GroupUserId = string;

export interface GroupUser {
  id: GroupUserId;
  name: string;
  username?: string;
  email?: string;
  isVisible: boolean;
}

export interface UserMap {
  [key: GroupUserId]: GroupUser;
}

export interface GroupPermission {
  user: GroupUser;
  isCurrentUser: boolean;
  permissions: PermissionName[];
}

function getUserName(dto?: UserDto): string {
  if (!dto) {
    return '';
  }
  if (dto.first_name || dto.last_name) {
    // Some users have only their first or their last name defined
    return (dto.first_name ?? '') + ' ' + (dto.last_name ?? '');
  }
  return dto.display_name ?? '';
}

export function mapToGroupUser(dto: UserDto): GroupUser {
  return {
    id: dto.id,
    name: getUserName(dto),
    username: dto.username && dto.username !== dto.email ? dto.username : undefined,
    email: dto.email,
    isVisible: true,
  };
}

export interface Invitation {
  groupId: GroupId;
  type: InvitationType;
  email: string;
  alias: string;
  invitationText: string;
}

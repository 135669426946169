import { CUSTOM_ELEMENTS_SCHEMA, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

const icons = {
  plus: 'add',
  delete: 'delete',
  edit: 'create',
  cancel: 'cancel',
  chevronRight: 'chevron_right',
} as const;

type ButtonIcon = keyof typeof icons;

type ButtonType = 'filled' | 'outline' | 'text';

@Component({
  selector: 'app-button',
  template: `
    <syn-button [icon]="buttonIcon" [attr.variant]="type" [disabled]="disabled" (click)="onSubmit($event)" #element>
      @if (icon) {
        <syn-icon slot="prefix" [name]="buttonIcon"></syn-icon>
      }
      <ng-content></ng-content>
    </syn-button>
  `,
  styles: [
    `
      syn-button {
        width: 100%;
      }
    `,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  standalone: true,
})
export class ButtonComponent {
  @Input() icon?: ButtonIcon;
  @Input() type: ButtonType = 'filled';
  @Input() disabled = false;

  @Output() submit = new EventEmitter<Event>();

  @ViewChild('element') element!: ElementRef;

  get buttonIcon() {
    return this.icon ? icons?.[this.icon] : undefined;
  }

  onSubmit(event: Event) {
    if (event.target === this.element.nativeElement) {
      this.submit.emit(event);
    }
  }
}

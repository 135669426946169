import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { createSelector, Store } from '@ngrx/store';
import { filter } from 'rxjs';
import { selectUser } from '../../auth/store/auth.feature';
import { PageChangeEvent } from '../group-list/group-list.component';
import { GroupDto, GroupMemberDto, InvitationDto, PermissionDto } from '../../api/dtos';
import { GroupPermission, GroupUser, UserMap } from '../model';
import {
  AddedMembersInfo,
  GrantedPermissionsInfo,
  groupDetailsActions,
  memberListActions,
} from '../store/group-details/group-details.slice';
import { selectGroupDetails, selectGroups, selectSupportView, selectUsers } from '../store/groups.feature';
import { Pages } from '../store/groups.pagination';
import { MemberListComponent } from '../components/member-list/member-list.component';
import { AsyncPipe } from '@angular/common';
import { SearchFieldComponent } from '../../shared/components/search-field/search-field.component';
import { PillComponent } from '../../shared/components/pill/pill.component';
import { GroupDetailsPropertiesComponent } from '../components/group-details-properties/group-details-properties.component';
import { BackLinkComponent } from '../../shared/components/back-link/back-link.component';

export interface CurrentPageViewModel<EntryT> {
  // The offset of the current page
  offset: number;
  // The entries on the current page
  entries: EntryT[];
  // The total number of entries (on all pages)
  totalCount: number;
  // The total number of (filtered) entries, i.e. how many entries are in the table
  tableCount: number;
  // The maximum number of entries on a single page
  pageLength: number;
  // True if the page is currently loaded
  loading: boolean;
}

export interface GroupDetailsViewModel {
  group: GroupDto;
  hasExternalMembers: boolean;
  currentUserIsFromSameCustomer: boolean;

  members: CurrentPageViewModel<GroupUser>;
  invitations: CurrentPageViewModel<InvitationDto>;
  permissions: CurrentPageViewModel<GroupPermission>;

  addedMembers: AddedMembersInfo | undefined;
  grantedPermissions: GrantedPermissionsInfo | undefined;
}

function idToUser(id: string, users: UserMap): GroupUser {
  const user = users[id];
  if (user) {
    return user;
  } else {
    return { id, name: '', isVisible: false };
  }
}

function dtoToMember(dto: GroupMemberDto, users: UserMap): GroupUser {
  return idToUser(dto.id, users);
}

function dtoToPermission(dto: PermissionDto, users: UserMap, currentUserId: string | undefined): GroupPermission {
  return {
    user: idToUser(dto.user_id, users),
    isCurrentUser: dto.user_id === currentUserId,
    permissions: dto.permissions.map((p) => p.name),
  };
}

function deserializeCurrentPage<DtoT, ResultT>(
  pages: Pages<DtoT>,
  deserializeElement: (dto: DtoT, users: UserMap) => ResultT,
  users: UserMap
) {
  const page = pages.pages[pages.offset];
  return {
    totalCount: pages.totalCount,
    tableCount: pages.tableCount,
    pageLength: pages.pageLength,
    offset: pages.offset,
    loading: pages.loading,
    entries: page?.map((dto) => deserializeElement(dto, users)) ?? [],
  };
}

export const selectGroupDetailsViewModel = createSelector(
  selectUser,
  selectGroups,
  selectUsers,
  selectGroupDetails,
  selectSupportView,
  (user, groups, users, groupDetails, supportView): GroupDetailsViewModel | undefined => {
    const details = groupDetails;
    if (!details) {
      return;
    }
    const group = groups?.[details.id];
    if (!group) {
      return;
    }

    const userMap = { ...users };
    if (user) {
      // Add the current user, so that an invited user can at least see themselves
      userMap[user.id] = {
        id: user.id,
        name: user.fullName,
        isVisible: true,
        email: user.email,
      };
    }

    const sameCustomer = user?.customerNumber !== undefined && user.customerNumber === group.customer_number;
    const bothSick = user?.email?.endsWith('@sick.de') === true && group.customer_number === 'SICK';
    const currentUserIsFromSameCustomer = supportView.active || sameCustomer || bothSick;

    return {
      group,

      hasExternalMembers: details.hasExternalMembers,
      currentUserIsFromSameCustomer,

      members: deserializeCurrentPage(groupDetails.memberPages, dtoToMember, userMap),
      invitations: deserializeCurrentPage(groupDetails.invitationPages, (i) => i, userMap),
      permissions: deserializeCurrentPage(
        groupDetails.permissionPages,
        (dto, users) => dtoToPermission(dto, users, user?.id),
        userMap
      ),

      addedMembers: groupDetails.addedMembers,
      grantedPermissions: groupDetails.grantedPermissions,
    };
  }
);

const selectViewModel = selectGroupDetailsViewModel;

@Component({
  selector: 'app-group-details',
  templateUrl: './group-details.component.html',
  styles: [],
  standalone: true,
  imports: [
    BackLinkComponent,
    GroupDetailsPropertiesComponent,
    PillComponent,
    SearchFieldComponent,
    MemberListComponent,
    AsyncPipe,
  ],
})
export class GroupDetailsComponent implements OnInit {
  private store = inject(Store);
  private groupId = inject(ActivatedRoute).snapshot.params['id'];
  readonly viewModel$ = this.store.select(selectViewModel).pipe(filter((viewModel) => viewModel !== undefined));
  @ViewChild(MemberListComponent) memberList!: MemberListComponent;

  ngOnInit(): void {
    this.store.dispatch(groupDetailsActions.pageInitialized(this.groupId));
  }

  onFilterMembers(text: string) {
    this.store.dispatch(memberListActions.filterChanged(this.groupId, text));
    // We have to reset the table to the first page if the user navigated to other pages
    this.memberList.table.first = 0;
  }

  onPageChange(event: PageChangeEvent) {
    this.store.dispatch(memberListActions.offsetChanged(event.offset));
  }
}

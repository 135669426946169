import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { errorHandlerActions } from '../../store/shared.feature';
import { ErrorType } from '../global-error-handler';
import { AbstractErrorListener } from './error-listener';

// markers used for key extraction to localization files
// TODO: Translate here!
const messageKeys = [
  'error.http.conflict',
  'error.http.forbidden',
  'error.http.invalidParameters',
  'error.http.maximumLengthExceeded',
  'error.http.notFound',
  'error.http.unspecifiedBadRequest',
  'error.http.unknown',
] as const;

export type HttpErrorMessageKey = (typeof messageKeys)[number];

export const HttpStatusCodes = {
  BadRequest: 400,
  Forbidden: 403,
  NotFound: 404,
  Conflict: 409,
};

export type HttpErrorCallback = (error: HttpErrorResponse) => boolean;

let callbacks: HttpErrorCallback[] = [];

export function registerHttpErrorCallback(listener: HttpErrorCallback) {
  callbacks.push(listener);
}

export function unregisterHttpErrorCallback(callback: HttpErrorCallback) {
  callbacks = callbacks.filter((entry) => entry != callback);
}

@Injectable({ providedIn: 'root' })
export class HttpErrorListener extends AbstractErrorListener {
  private store = inject(Store);

  override handleError(error: ErrorType) {
    if (!isHttpError(error)) {
      return super.handleError(error);
    } else {
      for (const callback of callbacks) {
        // If a callback handled the error, we can exit
        if (callback(error)) {
          return;
        }
      }
      this.store.dispatch(errorHandlerActions.errorReceived('ApiError', this.mapServerSideErrors(error)));
    }
  }

  private mapServerSideErrors(error: HttpErrorResponse): HttpErrorMessageKey {
    switch (error.status) {
      case HttpStatusCodes.BadRequest:
        return this.mapBadRequestErrorMessages(error.error);
      case HttpStatusCodes.NotFound:
        return 'error.http.notFound';
      case HttpStatusCodes.Conflict:
        return 'error.http.conflict';
      case HttpStatusCodes.Forbidden:
        return 'error.http.forbidden';
      default:
        return 'error.http.unknown';
    }
  }

  private mapBadRequestErrorMessages(error?: { name?: string }): HttpErrorMessageKey {
    if (error?.name === 'InvalidParameters') {
      return 'error.http.invalidParameters';
    }

    if (error?.name === 'MaximumLengthExceeded') {
      return 'error.http.maximumLengthExceeded';
    }

    return 'error.http.unspecifiedBadRequest';
  }
}

function isHttpError(error: ErrorType): error is HttpErrorResponse {
  return hasName(error) && error.name === 'HttpErrorResponse';
}

function hasName(value: unknown): value is { name: unknown } {
  return typeof value === 'object' && value !== null && 'name' in value;
}

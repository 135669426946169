import { Component, inject } from '@angular/core';
import { $localize } from '@angular/localize/init';
import { createSelector, Store } from '@ngrx/store';
import { AuthUser } from '../../auth/types';
import { selectUser } from '../../auth/store/auth.feature';
import { GroupPropertiesSubmitEvent } from '../model';
import { groupCreationActions } from '../store/groups.feature';
import { AsyncPipe } from '@angular/common';
import { GroupPropertiesFormComponent } from '../components/group-properties-form/group-properties-form.component';
import { BackLinkComponent } from '../../shared/components/back-link/back-link.component';

interface NewGroupViewModel {
  customerName: string | undefined;
}

export function createNewGroupViewModel(user: AuthUser | undefined): NewGroupViewModel {
  let customerName = user?.customerName;
  if (!customerName && user?.email?.endsWith('@sick.de')) {
    customerName = 'SICK AG';
  }
  return { customerName };
}

export const selectViewModel = createSelector(selectUser, createNewGroupViewModel);

@Component({
  selector: 'app-new-group',
  template: `
    <div class="margin-1">
      <app-back-link></app-back-link>
      <h2 i18n="@@newGroup.title">Neue Gruppe</h2>
      @if (viewModel$ | async; as viewModel) {
        <app-group-properties-form
          [customerName]="viewModel.customerName"
          [submitButton]="createGroup"
          [cancelButton]="true"
          (submit)="onSubmit($event)"
        >
        </app-group-properties-form>
      }
    </div>
  `,
  styles: [],
  standalone: true,
  imports: [BackLinkComponent, GroupPropertiesFormComponent, AsyncPipe],
})
export class NewGroupComponent {
  private store = inject(Store);
  readonly viewModel$ = this.store.select(selectViewModel);

  createGroup = $localize`:@@groupPropertiesForm.createGroup:Create Group`;

  onSubmit(event: GroupPropertiesSubmitEvent) {
    this.store.dispatch(groupCreationActions.groupCreationRequested(event));
  }
}

import { createActionGroup, emptyProps, on } from '@ngrx/store';
import {
  GroupInputDto,
  InvitationAndResultDto,
  InvitationInputListDto,
  PermissionChangeDto,
  PermissionDto,
} from '../../../api/dtos';
import { GroupId } from '../../model';
import { GroupsState, GroupStateReducerTypes } from '../groups.feature';
import {
  emptyPages,
  GroupMemberPages,
  GroupPermissionPages,
  InvitationPages,
  setLoadingState,
  setOffset,
} from '../groups.pagination';

export interface AddedMembersInfo {
  added: number;
  alreadyMember: number;
}

export interface GrantedPermissionsInfo {
  granted: number;
  alreadyGranted: number;
}

export interface SentInvitationResults {
  results: InvitationAndResultDto[];
}

export interface GroupDetailsSlice {
  id: GroupId;
  hasExternalMembers: boolean;
  savingProperties: boolean;
  memberPages: GroupMemberPages;
  invitationPages: InvitationPages;
  permissionPages: GroupPermissionPages;

  // Store information after members have been added to a group
  addedMembers: AddedMembersInfo | undefined;

  // Store information after permissions have been granted
  grantedPermissions: GrantedPermissionsInfo | undefined;

  // Store invitation results after invitations were sent to external users
  sentInvitations: SentInvitationResults | undefined;
}

export const groupDetailsActions = createActionGroup({
  source: 'Group Details Page',
  events: {
    'Page Initialized': (groupId: GroupId) => ({ groupId }),
  },
});

export const groupDetailsReducers: GroupStateReducerTypes[] = [
  on(groupDetailsActions.pageInitialized, initializeGroupDetails),
];

export function emptyGroupDetails(groupId: string): GroupDetailsSlice {
  return {
    id: groupId,
    hasExternalMembers: false,
    savingProperties: false,
    memberPages: emptyPages(),
    invitationPages: emptyPages(),
    permissionPages: emptyPages(),

    addedMembers: undefined,
    grantedPermissions: undefined,
    sentInvitations: undefined,
  };
}

function initializeGroupDetails(state: GroupsState, params: { groupId: string }): GroupsState {
  return {
    ...state,
    groupDetails: emptyGroupDetails(params.groupId),
  };
}

export const editGroupActions = createActionGroup({
  source: 'Edit Group Page',
  events: {
    'Page Initialized': (groupId: GroupId) => ({ groupId }),
    'Edit Group Properties': (groupId: GroupId) => ({ groupId }),
    'Properties Page Initialized': (groupId: GroupId) => ({ groupId }),
    'Add Members': (groupId: string, userIds: string[]) => ({ groupId, userIds }),
    'Add And Invite Members': (groupId: string, addUserIds: string[], invite: InvitationInputListDto) => ({
      groupId,
      addUserIds,
      invite,
    }),
    'Remove Members': (groupId: string, userIds: string[]) => ({ groupId, userIds }),
    'Change Permission': (groupId: string, change: PermissionChangeDto, grant: boolean) => ({ groupId, change, grant }),
    'Remove Granted Permissions Info': (groupId: string) => ({ groupId }),
    'Update Group Properties': (groupId: string, properties: GroupInputDto) => ({
      groupId,
      properties,
    }),
    'Create Invitations': (groupId: string, invite: InvitationInputListDto) => ({ groupId, invite }),
    'Clear Added Members': emptyProps(),
    'Delete Invitations': (groupId: string, code: string) => ({ groupId, code }),
  },
});

export function removeSuccessInfo(
  state: GroupsState,
  groupId: string,
  member: 'addedMembers' | 'grantedPermissions'
): GroupsState {
  if (state.groupDetails?.id !== groupId) {
    return state;
  }
  return {
    ...state,
    groupDetails: {
      ...state.groupDetails,
      [member]: undefined,
    },
  };
}

export const editGroupReducers: GroupStateReducerTypes[] = [
  on(editGroupActions.pageInitialized, initializeGroupDetails),
  on(editGroupActions.propertiesPageInitialized, initializeGroupDetails),
  on(editGroupActions.updateGroupProperties, (state): GroupsState => {
    if (!state.groupDetails) {
      return state;
    }
    // Store that we are currently saving properties (an API call is running)
    return {
      ...state,
      groupDetails: {
        ...state.groupDetails,
        savingProperties: true,
      },
    };
  }),
  on(editGroupActions.changePermission, (state, { groupId, change }): GroupsState => {
    const details = state.groupDetails;
    if (!details || details.id !== groupId) {
      return state;
    }

    // Update the state to prevent flickering of the permission dropdown
    const page = details.permissionPages.pages[details.permissionPages.offset];
    if (!page) {
      return state;
    }

    const newPage: PermissionDto[] = [];
    for (const entry of page) {
      const newEntry: PermissionDto = {
        user_id: entry.user_id,
        permissions: [...entry.permissions],
      };

      // Add permissions that are requested to be granted
      for (const add of change.add) {
        if (entry.user_id === add.user_id) {
          if (!entry.permissions.find((p) => p.name === add.name)) {
            newEntry.permissions.push({ name: add.name });
          }
        }
      }

      // Remove permissions that are requested to be revoked
      newEntry.permissions = newEntry.permissions.filter((p) => {
        const remove = change.remove.find((r) => r.user_id === entry.user_id && r.name === p.name);
        return !remove;
      });

      newPage.push(newEntry);
    }

    return {
      ...state,
      groupDetails: {
        ...details,
        permissionPages: {
          ...details.permissionPages,
          pages: { ...details.permissionPages.pages, [details.permissionPages.offset]: newPage },
        },
        // Remove the last info when permission changes are requested
        grantedPermissions: undefined,
      },
    };
  }),
  on(
    editGroupActions.removeGrantedPermissionsInfo,
    editGroupActions.changePermission,
    (state, { groupId }): GroupsState => {
      return removeSuccessInfo(state, groupId, 'grantedPermissions');
    }
  ),
  on(editGroupActions.clearAddedMembers, (state): GroupsState => {
    if (!state.groupDetails) {
      return state;
    }
    return {
      ...state,
      groupDetails: {
        ...state.groupDetails,
        sentInvitations: undefined,
        addedMembers: undefined,
      },
    };
  }),
];

export const memberListActions = createActionGroup({
  source: 'Member List',
  events: {
    'Offset Changed': (offset: number, invalidatePages?: boolean) => ({
      offset,
      invalidatePages: invalidatePages ?? false,
    }),
    'Filter Changed': (groupId: GroupId, text: string) => ({ groupId, text }),
    'Page Already Loaded': emptyProps(),
  },
});

export const memberListReducers: GroupStateReducerTypes[] = [
  on(memberListActions.offsetChanged, (state, { offset }): GroupsState => {
    return setOffset(state, 'memberPages', offset);
  }),
  on(memberListActions.filterChanged, (state): GroupsState => {
    // When the filter expression changes, we reset to offset 0
    // The table UI is also reset to the first page
    return setOffset(state, 'memberPages', 0);
  }),
  on(memberListActions.pageAlreadyLoaded, (state): GroupsState => setLoadingState(state, 'memberPages')),
];

export const permissionListActions = createActionGroup({
  source: 'Permission List',
  events: {
    'Offset Changed': (offset: number, invalidatePages?: boolean) => ({
      offset,
      invalidatePages: invalidatePages ?? false,
    }),
    'Filter Changed': (groupId: string, text: string) => ({ groupId, text }),
    'Page Already Loaded': emptyProps(),
  },
});

export const permissionListReducers: GroupStateReducerTypes[] = [
  on(permissionListActions.offsetChanged, (state, { offset }): GroupsState => {
    return setOffset(state, 'permissionPages', offset);
  }),
  on(permissionListActions.filterChanged, (state): GroupsState => {
    // When the filter expression changes, we reset to offset 0
    // The table UI is also reset to the first page
    return setOffset(state, 'permissionPages', 0);
  }),
  on(permissionListActions.pageAlreadyLoaded, (state): GroupsState => setLoadingState(state, 'permissionPages')),
];

export const invitationListActions = createActionGroup({
  source: 'Invitation List',
  events: {
    'Offset Changed': (offset: number, invalidatePages?: boolean) => ({
      offset,
      invalidatePages: invalidatePages ?? false,
    }),
    'Page Already Loaded': emptyProps(),
  },
});

export const invitationListReducers: GroupStateReducerTypes[] = [
  on(invitationListActions.offsetChanged, (state, { offset }): GroupsState => {
    return setOffset(state, 'invitationPages', offset);
  }),
  on(invitationListActions.pageAlreadyLoaded, (state): GroupsState => setLoadingState(state, 'invitationPages')),
];

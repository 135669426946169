import { CUSTOM_ELEMENTS_SCHEMA, Component, ElementRef, ViewChild, inject } from '@angular/core';
import { createSelector, Store } from '@ngrx/store';
import { authActions, selectIsAuthenticated, selectUser } from '../../../auth/store/auth.feature';
import { headerComponentActions, sharedSelectors } from '../../store/shared.feature';
import { environment } from '../../../../environments/environment';
import { selectSupportView, SupportViewSlice } from '../../../groups/store/groups.feature';
import { AsyncPipe } from '@angular/common';
import { ContactSupportComponent } from '../contact-support/contact-support.component';
import { ICON_PERSON, ICON_WORLD } from '../icons';
import { SUPPORTED_LANGUAGES, Language, I18n } from '../../../i18n';
import { ToggleSwitchComponent } from '../toggle-switch/toggle-switch.component';
import { ButtonComponent } from '../button/button.component';

SUPPORTED_LANGUAGES;

interface HeaderViewModel {
  userName?: string;
  userEmail?: string;
  isAuthenticated: boolean;
  supportView: SupportViewSlice;
  availableLocales: Language[];
  activeLocale: string;
}

const selectViewModel = createSelector(
  selectUser,
  selectIsAuthenticated,
  selectSupportView,
  sharedSelectors.selectActiveLocale,
  (user, isAuthenticated, supportView, activeLocale): HeaderViewModel => ({
    userName: user?.fullName,
    userEmail: user?.email,
    isAuthenticated,
    supportView,
    availableLocales: SUPPORTED_LANGUAGES,
    activeLocale,
  })
);

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styles: [
    `
      .vertical {
        display: flex;
        flex-direction: column;
        margin: auto;
      }
      .horizontal {
        display: flex;
        flex-direction: row;
      }
      .menu {
        padding: 0.8em;
        row-gap: 0.8em;
      }
      .header-border {
        border-bottom: 3px solid #bac2c6;
      }
      .email {
        padding-top: 0.2em;
        font-size: smaller;
      }
      .account-icon {
        width: 36px;
        height: 36px;
      }
      .label {
        padding-right: 0.5rem;
      }

      app-button {
        width: 100%;
      }
    `,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  standalone: true,
  imports: [ContactSupportComponent, ToggleSwitchComponent, ButtonComponent, AsyncPipe],
})
export class HeaderComponent {
  private store = inject(Store);
  private i18n = inject(I18n);
  readonly viewModel$ = this.store.select(selectViewModel);
  myAccountLink = environment.auth.url + `/realms/${environment.auth.realm}/account`;

  iconLanguage = ICON_WORLD;
  iconUser = ICON_PERSON;

  @ViewChild('languageMenu') languageMenu: ElementRef | undefined;

  onLanguageSelection(event: Event) {
    if (event.target === this.languageMenu?.nativeElement) {
      const languageId = (event as CustomEvent).detail.item.value;
      this.i18n.setLanguage(languageId);
      // Reload the page after we changed the language
      location.reload();
    }
  }

  isCurrentLanguage(id: string): boolean | null {
    return this.i18n.locale === id ? true : null;
  }

  onSupportViewChange(checked: boolean) {
    this.store.dispatch(headerComponentActions.supportViewChanged(checked));
  }

  onLogout() {
    this.store.dispatch(authActions.logoutRequested());
  }
}

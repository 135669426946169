import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { GroupUser } from '../model';
import { PermissionName } from '../../api/dtos';
import { UserSearchFieldComponent } from '../components/user-search-field/user-search-field.component';
import { ButtonComponent } from '../../shared/components/button/button.component';
import { NgStyle } from '@angular/common';
import { TableDirective } from '../../shared/components/table/table.directive';
import { TableComponent } from '../../shared/components/table/table.component';
import { PillComponent } from '../../shared/components/pill/pill.component';
import { PermissionDropdownComponent } from '../components/permission-dropdown/permission-dropdown.component';
import { DialogComponent } from '../../shared/components/dialog/dialog.component';

export interface UserAndPermission {
  user: GroupUser;
  permission: PermissionName;
}

@Component({
  selector: 'app-grant-permissions',
  template: `<app-dialog
    (onHide)="onHide()"
    i18n-header="@@grantPermissionsDialog.grantPermissions"
    header="Grant Permissions"
    width="60vw"
  >
    <div style="height: 60vh">
      <div class="flex-row col-gap-1">
        <app-user-search-field
          id="grant-permission-search"
          i18n-placeholder="@@grantPermissionsDialog.typeToSearchUser"
          placeholder="Type an email address or search for a user"
          (userSelected)="onUserAdded($event)"
          class="flex-grow-1"
        ></app-user-search-field>
        <app-permission-dropdown [(value)]="selectedPermission"></app-permission-dropdown>
      </div>
      <h6 i18n="@@grantPermissionsDialog.selected">
        Selected: <app-pill>{{ selectedUsers.length }}</app-pill>
      </h6>
      @if (selectedUsers.length === 0) {
        <div i18n="@@grantPermissionsDialog.noUserMessage">No user is selected.</div>
      } @else {
        <app-table
          id="grant-permission-selected"
          [values]="selectedUsers"
          [showPagination]="false"
          scrollHeight="24rem"
        >
          <ng-template tableTemplate="header"
            ><tr>
              <th i18n="@@grantPermissionsDialog.selectedList.name">Name</th>
              <th i18n="@@grantPermissionsDialog.selectedList.email">Email Address</th>
              <th i18n="@@grantPermissionsDialog.selectedList.permission">Permission</th>
              <th i18n="@@remove">Remove</th>
            </tr>
          </ng-template>
          <ng-template tableTemplate="body" let-user>
            <tr>
              <td [ngStyle]="{ width: '35%' }">
                <span>{{ user.user.name + (user.user.username ? ' (' + user.user.username + ')' : '') }}</span>
              </td>
              <td [ngStyle]="{ width: '30%' }">{{ user.user.email }}</td>
              <td [ngStyle]="{ width: '30%' }">
                <app-permission-dropdown [(value)]="user.permission"></app-permission-dropdown>
              </td>
              <td [ngStyle]="{ width: '10%' }">
                <app-button type="text" icon="delete" (submit)="onRemoveUser(user)"></app-button>
              </td>
            </tr>
          </ng-template>
        </app-table>
      }
    </div>
    <div class="flex-end-1" slot="footer">
      <app-button id="grant-permission-cancel" type="outline" (submit)="onCancelDialog()" i18n="@@cancel"
        >Cancel</app-button
      >
      <app-button
        id="grant-permission-submit"
        (submit)="onSubmitDialog()"
        [disabled]="!isDialogSubmittable()"
        i18n="@@grantPermissionsDialog.grantPermissions"
      >
        Grant Permissions
      </app-button>
    </div>
  </app-dialog>`,
  styles: [``],
  standalone: true,
  imports: [
    DialogComponent,
    UserSearchFieldComponent,
    PermissionDropdownComponent,
    PillComponent,
    TableComponent,
    TableDirective,
    NgStyle,
    ButtonComponent,
  ],
})
export class GrantPermissionsComponent {
  selectedUsers: UserAndPermission[] = [];
  selectedPermission: PermissionName = 'view-group';

  @Output() submit = new EventEmitter<UserAndPermission[]>();

  @ViewChild(UserSearchFieldComponent) userSearchField!: UserSearchFieldComponent;
  @ViewChild(DialogComponent) dialog!: DialogComponent;

  onPermissionSelected(event: Event) {
    const customEvent = event as CustomEvent<PermissionName>;
    this.selectedPermission = customEvent.detail;
  }

  onHide() {
    this.selectedUsers = [];
    this.selectedPermission = 'view-group';
    this.userSearchField.clear();
  }

  onUserAdded(user: GroupUser) {
    const found = this.selectedUsers.find((u) => u.user === user);
    if (found) {
      // Overwrite the permission if the user was already added
      found.permission = this.selectedPermission;
      return;
    }

    this.selectedUsers.push({ user, permission: this.selectedPermission });
    console.log('Added', this.selectedUsers);
  }

  onRemoveUser(user: UserAndPermission) {
    this.selectedUsers = this.selectedUsers.filter((u) => u !== user);
    console.log('User removed', user);
  }

  onCancelDialog() {
    this.dialog.hide();
  }

  isDialogSubmittable() {
    return this.selectedUsers.length > 0;
  }

  onSubmitDialog() {
    this.submit.emit(this.selectedUsers);
    this.dialog.hide();
  }
}

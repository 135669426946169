import {
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

type OnChangeFn = (inputText: string) => unknown;
type OnTouchedFn = () => unknown;

@Component({
  selector: 'app-text-area',
  template: `
    <syn-textarea
      [attr.placeholder]="placeholder"
      [label]="label"
      [attr.disabled]="disabled ? true : null"
      [attr.readonly]="readonly ? true : null"
      [attr.size]="size"
      [attr.rows]="rows"
      [attr.maxlength]="maxLength"
      [attr.help-text]="error"
      [value]="text"
      resize="none"
      (syn-input)="onInput($event)"
      (syn-blur)="onBlur($event)"
      #element
    ></syn-textarea>
  `,
  styles: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: TextAreaComponent,
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  standalone: true,
  imports: [],
})
export class TextAreaComponent implements ControlValueAccessor, OnChanges {
  @Input() placeholder = '';
  @Input() label = '';
  @Input() disabled = false;
  @Input() readonly = false;
  @Input() error: string | null = null;
  @Input() text = '';
  @Input() size: 'small' | 'medium' | 'large' = 'medium';
  @Input() rows = 4;
  @Input() maxLength: number | null = null;
  @Output() textChange = new EventEmitter<string>();

  @ViewChild('element') element: ElementRef<HTMLInputElement> | undefined;

  private isTouched = false;
  private onChange: OnChangeFn = () => {};
  private onTouched: OnTouchedFn = () => {};

  writeValue(inputText: string) {
    this.text = inputText;
  }

  registerOnChange(onChange: OnChangeFn): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: OnTouchedFn): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onInput(event: Event) {
    if (event.target === this.element?.nativeElement) {
      this.text = (event.target as HTMLInputElement).value;
      this.onChange(this.text);
      this.textChange.emit(this.text);
    }
  }

  onBlur(event: Event) {
    if (event.target === this.element?.nativeElement) {
      if (this.isTouched) return;
      this.onTouched();
      this.isTouched = true;
    }
  }

  // Since synergy seems to not offer a attribute to mark a component as invalid
  // we have to use the setCustomValidity function to mark a component.
  // What we would want to have:
  // <syn-textarea [attr.invalid]="true/false" >
  // Currently waiting to see whether the synergy people can provide a better method:
  // https://github.com/synergy-design-system/synergy-design-system/issues/574
  ngOnChanges(changes: SimpleChanges): void {
    const errorChange = changes['error'];
    if (errorChange) {
      // The only way I found to mark an element as invalid is the setCustomValidity function.
      // An empty string marks the element as valid, a non-empty string as invalid
      this.element?.nativeElement?.setCustomValidity(this.error ?? '');

      // The reportValidity function shows a strange popup (probably HTML5 stuff?)
      // We do not want it for now.
      // this.element?.nativeElement?.reportValidity();

      // When the validity is first reported as false, the synergy field is not marked red
      // If we click on another component, i.e. lose focus, it gets marked red.
      // Therefore, we trigger this reaction by losing focus and immediatly regaining focus.
      // This might cause some undesired behavior for the user.
      this.element?.nativeElement?.blur();
      this.element?.nativeElement?.focus();
    }
  }
}

import { registerLocaleData } from '@angular/common';
import { Injectable, LOCALE_ID } from '@angular/core';
import { loadTranslations } from '@angular/localize';

import localeEn from '@angular/common/locales/en';
import localeDe from '@angular/common/locales/de';

import translationsEn from 'src/assets/i18n/en.json';
import translationsDe from 'src/assets/i18n/de.json';

import { authUser } from './auth/auth';

type Translation = Record<string, string>;

export interface Language {
  id: string;
  label: string;
  locale: unknown;
  translation: Translation;
}

export const SUPPORTED_LANGUAGES: Language[] = [
  {
    id: 'de',
    label: 'Deutsch',
    locale: localeDe,
    translation: translationsDe,
  },
  {
    id: 'en',
    label: 'English',
    locale: localeEn,
    translation: translationsEn,
  },
];

const SUPPORTED_LANGUAGE_IDS = SUPPORTED_LANGUAGES.map((lang) => lang.id);

export function isSupportedLocale(value: string): boolean {
  return SUPPORTED_LANGUAGE_IDS.includes(value);
}

@Injectable({
  providedIn: 'root',
})
export class I18n {
  locale = 'en';

  setLanguage(id: string) {
    localStorage.setItem('language', id);
    this.locale = id;
  }

  async setLocale() {
    const storedLanguage = localStorage.getItem('language');
    const userLocale = storedLanguage || authUser?.locale || 'en';

    let localeModule: unknown;
    let translation: Translation;
    const lang = SUPPORTED_LANGUAGES.find((lang) => lang.id === userLocale);
    if (lang && isSupportedLocale(userLocale)) {
      localeModule = lang.locale;
      translation = lang.translation;
      this.locale = userLocale;
    } else {
      console.warn(`Unsupported locale '${userLocale}'. Defaulting to 'en'.`);
      localeModule = localeEn;
      translation = translationsEn;
      this.locale = 'en';
    }

    // Set locale for built in pipes, etc.
    registerLocaleData(localeModule, this.locale);

    // Load translations for the current locale at run-time
    loadTranslations(translation);

    console.log('Loaded language', this.locale);
  }
}

// Set the runtime locale for the app
export function setLocaleId() {
  return {
    provide: LOCALE_ID,
    useFactory: (i18n: I18n) => {
      return i18n.locale;
    },
    deps: [I18n],
  };
}

import { ErrorHandler, Injectable, Injector, Type, inject } from '@angular/core';
import { ErrorListener, ERROR_LISTENERS } from './error-listener/error-listener';
import { FallbackErrorListener } from './error-listener/fallback-error-listener';
import { HttpErrorListener } from './error-listener/http-error-listener';

export type ErrorType = unknown;

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  private injector = inject(Injector);
  private registeredErrorListenerTypes: Type<ErrorListener>[] = [HttpErrorListener, FallbackErrorListener];
  private _errorListenerHead?: ErrorListener;

  handleError(error: ErrorType): void {
    this.log(error);
    this.errorListenerHead.handleError(error);
  }

  private get errorListenerHead(): ErrorListener {
    if (!this._errorListenerHead) {
      const head = buildErrorListenerChain(
        this.injector.get<ErrorListener[]>(ERROR_LISTENERS, []),
        this.injector.get(FallbackErrorListener)
      );
      this._errorListenerHead = head;
      return head;
    }

    return this._errorListenerHead;
  }

  private log(error: ErrorType): void {
    console.error(error);
  }
}

function buildErrorListenerChain(listeners: ErrorListener[], fallbackListener: ErrorListener) {
  const headListener = listeners[0] ?? fallbackListener;

  if (listeners.length > 0) {
    const tailListener = listeners.reduce((current, next) => current.setNextListener(next));
    tailListener.setNextListener(fallbackListener);
  }

  return headListener;
}

import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterViewInit,
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import {
  Observable,
  Subscription,
  catchError,
  debounceTime,
  distinctUntilChanged,
  finalize,
  fromEvent,
  map,
  of,
  switchMap,
} from 'rxjs';
import { UserDto } from '../../../api/dtos';
import { GroupHttpApi } from '../../../api/http';
import { GroupUser, mapToGroupUser } from '../../model';

export interface UserDropdownOption {
  user: GroupUser;
  label: string;
}

export function mapToUserLabel(user: GroupUser): string {
  // Include the username if it differs from the email to distinguish test users
  let suffix = '';
  if (user.email && user.username && user.email !== user.username) {
    suffix = ' (' + user.email + ', ' + user.username + ')';
  } else if (user.email) {
    suffix = ' (' + user.email + ')';
  }

  return user.name + suffix;
}

function mapToDropdownOption(dto: UserDto): UserDropdownOption {
  const user = mapToGroupUser(dto);
  const label = mapToUserLabel(user);
  return { user, label };
}

type UserSearchResult =
  | {
      status: 'ok';
      users: UserDto[];
    }
  | {
      status: 'error';
      error: unknown;
    };

export interface MenuItemSelected {
  item: HTMLInputElement;
}

function mapToSearchResult(users: UserDto[]): UserSearchResult {
  return {
    status: 'ok',
    users,
  };
}

function mapToError(error: unknown): UserSearchResult {
  return {
    status: 'error',
    error: error,
  };
}

@Component({
  selector: 'app-user-search-field',
  template: `
    <syn-input
      slot="trigger"
      [attr.placeholder]="placeholder"
      clearable="true"
      autocomplete="off"
      [attr.error]="warning ? true : null"
      [attr.help-text]="warning"
      (window:resize)="positionUserSelectionPopup()"
      #searchField
    >
      <syn-icon slot="prefix" name="search"></syn-icon>
      @if (isLoading) {
        <syn-spinner slot="suffix"></syn-spinner>
      }
    </syn-input>
    <div style="position: absolute; z-index: 9999;" [style.left]="userOptionsLeft" [style.top]="userOptionsTop">
      @if (userOptions.length > 0) {
        <syn-menu #userSelection (syn-select)="onChange($event)">
          @for (option of userOptions; track option.user.id) {
            <syn-menu-item [attr.value]="option.user.id">
              {{ option.label }}
            </syn-menu-item>
          }
        </syn-menu>
      }
    </div>
  `,
  styles: [``],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  standalone: true,
  imports: [],
})
export class UserSearchFieldComponent implements AfterViewInit, OnDestroy {
  @Input() placeholder = '';
  @Input() label = '';
  // Maximum number of user options to request / show
  @Input() maxOptions = 8;

  // Emits an event if a user is selected
  @Output() userSelected = new EventEmitter<GroupUser>();

  @ViewChild('searchField') searchField!: ElementRef<HTMLInputElement>;
  @ViewChild('userSelection') userSelection: ElementRef | undefined;

  userOptions: UserDropdownOption[] = [];
  isLoading = false;
  warning?: string;
  text: string = '';
  userOptionsLeft = '2px';
  userOptionsTop = '2px';

  static readonly DEBOUNCE_TIME = 300;
  static readonly MIN_SEARCH_TEXT_LENGTH = 3;

  private subscription?: Subscription;
  private service = inject(GroupHttpApi);

  ngAfterViewInit(): void {
    this.subscription = this.subscribeToInput();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  clear() {
    this.searchField.nativeElement.value = '';
  }

  getText() {
    return this.searchField.nativeElement.value;
  }

  private subscribeToInput() {
    const searchTextEvents = fromEvent(this.searchField.nativeElement, 'input').pipe(
      debounceTime(UserSearchFieldComponent.DEBOUNCE_TIME),
      map((event) => (event.target as HTMLInputElement).value),
      distinctUntilChanged()
    );
    return searchTextEvents
      .pipe(
        switchMap((searchText) => {
          // The User Search SPI checks the string length after trimming it
          // Therefore, we need to trim before checking, otherwise we get a 400 Bad Request
          const trimmedText = searchText.trim();
          if (trimmedText.length < UserSearchFieldComponent.MIN_SEARCH_TEXT_LENGTH) {
            return this.resetUserSearchOptions();
          }
          return this.requestUserSearch(searchText);
        })
      )
      .subscribe((users) => this.handleResult(users));
  }

  private resetUserSearchOptions(): Observable<UserSearchResult> {
    return of(mapToSearchResult([]));
  }

  private requestUserSearch(searchText: string): Observable<UserSearchResult> {
    this.isLoading = true;
    this.text = searchText;
    return this.service.searchUsers(searchText, this.maxOptions).pipe(
      map((dtos) => mapToSearchResult(dtos)),
      catchError((error) => of(mapToError(error))),
      finalize(() => (this.isLoading = false))
    );
  }

  private handleResult(searchResult: UserSearchResult) {
    if (searchResult.status === 'ok') {
      this.userOptions = searchResult.users.map(mapToDropdownOption);
      this.warning = undefined;
      this.searchField.nativeElement.setCustomValidity('');

      this.positionUserSelectionPopup();
    } else if (searchResult.error instanceof HttpErrorResponse) {
      this.handleErrorResponse(searchResult.error);
    }
  }

  private handleErrorResponse(error: HttpErrorResponse) {
    if (error.status === 503) {
      this.warning = $localize`:@@userSearch.timeoutError:The user search took too long. Please modify your search text to try again.`;

      this.searchField.nativeElement.setCustomValidity(this.warning);
      this.searchField.nativeElement.blur();
      this.searchField.nativeElement.focus();

      this.userOptions = [];
    }
  }

  positionUserSelectionPopup() {
    const rect = this.searchField.nativeElement.getBoundingClientRect();
    this.userOptionsLeft = rect.left + 'px';
    this.userOptionsTop = rect.bottom + 'px';
  }

  onChange(event: Event) {
    const customEvent = event as CustomEvent<MenuItemSelected>;
    if (event.target === this.userSelection?.nativeElement) {
      const selectedUserId = customEvent.detail.item.value;
      const selectedOption = this.userOptions.find((option) => option.user.id === selectedUserId);
      if (selectedOption) {
        // Clear the search field after selection
        this.searchField.nativeElement.value = '';
        this.userOptions = [];
        this.userSelected.emit(selectedOption.user);
      }
    }
  }
}

import { Injectable, inject } from '@angular/core';
import { $localize } from '@angular/localize/init';
import { Store } from '@ngrx/store';
import { errorHandlerActions } from '../../store/shared.feature';
import { ErrorType } from '../global-error-handler';
import { AbstractErrorListener } from './error-listener';

@Injectable({ providedIn: 'root' })
export class FallbackErrorListener extends AbstractErrorListener {
  private store = inject(Store);

  override handleError(_error: ErrorType) {
    const message = $localize`:@@error.fallbackErrorMessage:Oops, something went wrong. We encountered an unknown error.`;
    this.store.dispatch(errorHandlerActions.errorReceived('UnknownError', message));
  }
}

import { routerNavigationAction } from '@ngrx/router-store';
import { createActionGroup, createFeature, createReducer, emptyProps, on } from '@ngrx/store';

export type ErrorDescriptor = 'ApiError' | 'UnknownError';

export interface ApplicationError {
  errorDescriptor: ErrorDescriptor;
  errorMessageKey: string;
}

export interface SharedState {
  openError: ApplicationError | null;
  activeLocale: string;
}

export const initialState: SharedState = {
  openError: null,
  activeLocale: 'en',
};

export const errorHandlerActions = createActionGroup({
  source: 'Error Handler',
  events: {
    'Error Received': (descriptor: ErrorDescriptor, messgeKey: string): ApplicationError => ({
      errorDescriptor: descriptor,
      errorMessageKey: messgeKey,
    }),
  },
});

export const appInitializerActions = createActionGroup({
  source: 'App Initializer',
  events: {
    'Authentication Initialized': emptyProps(),
  },
});

export const i18nActions = createActionGroup({
  source: 'I18n',
  events: {
    'Language Updated': (locale: string) => ({ locale }),
  },
});

export const headerComponentActions = createActionGroup({
  source: 'HeaderComponent',
  events: {
    'Language Change Requested': (locale: string) => ({ locale }),
    'Support View Changed': (active: boolean) => ({ active }),
  },
});

export const sharedFeature = createFeature({
  name: 'shared',
  reducer: createReducer(
    initialState,
    on(
      errorHandlerActions.errorReceived,
      (state, { errorDescriptor, errorMessageKey }): SharedState => ({
        ...state,
        openError: { errorDescriptor, errorMessageKey },
      })
    ),
    on(
      routerNavigationAction,
      (state): SharedState => ({
        ...state,
        openError: initialState.openError,
      })
    ),
    on(
      i18nActions.languageUpdated,
      (state, { locale }): SharedState => ({
        ...state,
        activeLocale: locale,
      })
    )
  ),
});

export const { name, reducer, ...sharedSelectors } = sharedFeature;

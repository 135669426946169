import {
  ActionCreator,
  createActionGroup,
  createFeature,
  createReducer,
  Creator,
  emptyProps,
  on,
  ReducerTypes,
} from '@ngrx/store';
import { groupApiActions } from '../../api/actions';
import {
  AcceptedInvitationCountDto,
  GroupDto,
  GroupMemberDto,
  GroupRelation,
  InvitationResponseOption,
  InvitationWithSenderInfoDto,
  PermissionChangeResult,
  PermissionDto,
} from '../../api/dtos';
import { InvitationPageParams } from '../../api/group';
import { headerComponentActions } from '../../shared/store/shared.feature';
import { GroupId, GroupPropertiesSubmitEvent, UserMap } from '../model';
import {
  AddedMembersInfo,
  editGroupReducers,
  GrantedPermissionsInfo,
  groupDetailsReducers,
  GroupDetailsSlice,
  invitationListReducers,
  memberListReducers,
  permissionListReducers,
} from './group-details/group-details.slice';
import { mergePages, mergeUsers, PageCache, updatePage } from './groups.pagination';

export interface GroupListSlice {
  searchQuery: string;
  pages: { [key: number]: GroupId[] };
  offset: number;
  pageLength: number;
  totalCount: number;
  originalTotalCount: number;
  listType: GroupRelation;
}

export interface GroupMap {
  [key: GroupId]: GroupDto;
}

export interface InvitationSlice {
  data: InvitationWithSenderInfoDto | undefined;
  sendingResponse: boolean;
}

export interface SupportViewSlice {
  canActivate: boolean;
  active: boolean;
}

export interface GroupsState {
  groups: GroupMap;
  users: UserMap;

  lists: Record<GroupRelation, GroupListSlice>;
  openList: GroupRelation;
  listsValid: boolean;

  groupDetails: GroupDetailsSlice | undefined;
  invitation: InvitationSlice;

  supportView: SupportViewSlice;
}

export function emptyGroupList(relation: GroupRelation): GroupListSlice {
  return {
    searchQuery: '',
    pages: {},
    offset: 0,
    pageLength: 10,
    totalCount: 0,
    originalTotalCount: 0,
    listType: relation,
  };
}

export const initialState: GroupsState = {
  groups: {},
  users: {},
  lists: {
    member: emptyGroupList('member'),
    manager: emptyGroupList('manager'),
    visible: emptyGroupList('visible'),
  },
  openList: 'manager',
  listsValid: false,
  groupDetails: undefined,
  invitation: {
    data: undefined,
    sendingResponse: false,
  },

  supportView: { canActivate: false, active: false },
};

export type GroupStateReducerTypes = ReducerTypes<
  GroupsState,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly ActionCreator<string, Creator<any[], object>>[]
>;

export const groupListActions = createActionGroup({
  source: 'Group List',
  events: {
    Initialized: emptyProps(),
    Opened: (listType: GroupRelation, first: boolean) => ({ listType, first }),
    'Search Query Changed': (query: string) => ({ query }),
    'Offset Changed': (offset: number) => ({ offset }),
    'Page Already Loaded': emptyProps(),
    'Deletion Requested': (groupId: GroupId) => ({ groupId }),
  },
});

const groupListReducers: GroupStateReducerTypes[] = [
  on(groupListActions.opened, (state, { listType }): GroupsState => {
    const newLists = {} as Record<GroupRelation, GroupListSlice>;
    let relation: GroupRelation;
    for (relation in state.lists) {
      const list = state.lists[relation];
      newLists[relation] = {
        ...list,
        // Reset total count when switching tabs
        // This done since the search text is reset in the UI
        totalCount: list.originalTotalCount,
      };
    }
    return {
      ...state,
      groupDetails: undefined,
      lists: state.lists,
      openList: listType,
    };
  }),
  on(groupListActions.searchQueryChanged, (state, { query }): GroupsState => {
    const list = state.lists[state.openList];
    const newState: GroupsState = {
      ...state,
      lists: { ...state.lists, [state.openList]: { ...list, searchQuery: query } },
    };
    return newState;
  }),
  on(groupListActions.offsetChanged, (state, { offset }): GroupsState => {
    const list = state.lists[state.openList];
    const newState: GroupsState = {
      ...state,
      lists: { ...state.lists, [state.openList]: { ...list, offset: offset } },
    };
    return newState;
  }),
];

export const groupCreationActions = createActionGroup({
  source: 'New Group',
  events: {
    'Group Creation Requested': (event: GroupPropertiesSubmitEvent) => ({
      event,
    }),
  },
});

function updateGroup(state: GroupsState, group: GroupDto): GroupsState {
  return {
    ...state,
    groups: { ...state.groups, [group.id]: group },
  };
}

function updateExternalUsers(state: GroupsState, invs: AcceptedInvitationCountDto[]): GroupsState {
  const hasExternalMembers = invs.findIndex((i) => i.type === 'member' && i.count > 0) >= 0;
  const details = state.groupDetails;
  if (!details) {
    return state;
  }

  // NOTE: Do we need information about external managers / viewers as well?
  //       The current UI design only includes members (2023-01-24)
  return {
    ...state,
    groupDetails: {
      ...details,
      hasExternalMembers,
    },
  };
}

function addGroups(result: Record<GroupId, GroupDto>, source: GroupDto[]) {
  for (const group of source) {
    result[group.id] = group;
  }
}

const groupApiReducers: GroupStateReducerTypes[] = [
  on(groupApiActions.overviewLoaded, (state, { result, search }): GroupsState => {
    const newGroups: Record<GroupId, GroupDto> = {};
    addGroups(newGroups, result.member.page);
    addGroups(newGroups, result.manager.page);
    addGroups(newGroups, result.visible.page);

    function newList(relation: GroupRelation): GroupListSlice {
      const list = state.lists[relation];
      return {
        ...list,
        totalCount: result[relation].totalCount,
        originalTotalCount: search ? state.lists[relation].originalTotalCount : result[relation].totalCount,
        pages: { [list.offset]: result[relation].page.map((group) => group.id) },
      };
    }

    const newState: GroupsState = {
      ...state,
      supportView: { canActivate: result.supportView, active: state.supportView.active },
      groups: { ...state.groups, ...newGroups },
      lists: {
        manager: newList('manager'),
        member: newList('member'),
        visible: newList('visible'),
      },
      listsValid: true,
    };
    return newState;
  }),
  on(groupApiActions.groupPageLoaded, (state, { page, offset, relation, invalidatePages }): GroupsState => {
    const newGroups: Record<GroupId, GroupDto> = {};
    addGroups(newGroups, page.page);
    const list = state.lists[relation];
    const idPage = page.page.map((group) => group.id);
    const pages = invalidatePages ? { [offset]: idPage } : { ...list.pages, [offset]: idPage };
    const newState: GroupsState = {
      ...state,
      groups: { ...state.groups, ...newGroups },
      lists: {
        ...state.lists,
        [relation]: {
          ...list,
          totalCount: page.totalCount,
          offset,
          pages,
        },
      },
    };
    return newState;
  }),
  on(groupApiActions.groupLoaded, (state, { group }): GroupsState => updateGroup(state, group)),
  on(groupApiActions.groupCreated, (state): GroupsState => {
    return {
      ...state,
      // We invalidate the lists if a new group is created
      listsValid: false,
    };
  }),
  on(groupApiActions.groupUpdated, (state, { group }): GroupsState => {
    const newState = updateGroup(state, group);
    if (newState.groupDetails) {
      return {
        ...newState,
        groupDetails: {
          ...newState.groupDetails,
          savingProperties: false,
        },
      };
    }
    return newState;
  }),
  on(groupApiActions.groupDetailsLoaded, (state, { result }): GroupsState => {
    let newState = updateGroup(state, result.group);
    newState = updateExternalUsers(newState, result.acceptedInvitations);
    newState = updatePage(newState, result.members, result.users, 'memberPages');
    newState = updatePage(newState, result.invitations, result.users, 'invitationPages');
    newState = updatePage(newState, result.permissions, result.users, 'permissionPages');
    return newState;
  }),
  on(groupApiActions.membersLoaded, (state, event): GroupsState => mergePages(state, event, 'memberPages')),
  on(groupApiActions.invitationsLoaded, (state, event): GroupsState => mergePages(state, event, 'invitationPages')),
  on(groupApiActions.permissionsLoaded, (state, event): GroupsState => mergePages(state, event, 'permissionPages')),
  on(groupApiActions.invitationsCreated, (state, { groupId, result }): GroupsState => {
    if (state.groupDetails?.id !== groupId) {
      return state;
    }

    return {
      ...state,
      groupDetails: {
        ...state.groupDetails,
        sentInvitations: { results: [...result.results] },
      },
    };
  }),

  on(groupApiActions.membersFiltered, (state, event): GroupsState => {
    const details = state.groupDetails;
    if (!details) {
      return state;
    }

    const members = event.result.members;
    if (!members) {
      throw new Error('Expected members to be filled');
    }
    const cache: PageCache<GroupMemberDto> = { [0]: members };

    const groupDetails: GroupDetailsSlice = {
      ...details,
      memberPages: {
        ...details.memberPages,
        loading: false,
        offset: 0,
        pages: cache,
        tableCount: members.length,
        filter: event.text,
      },
    };
    return {
      ...state,
      users: mergeUsers(state.users, event.result.users),
      groupDetails,
    };
  }),

  on(groupApiActions.permissionsFiltered, (state, event): GroupsState => {
    const details = state.groupDetails;
    if (!details) {
      return state;
    }

    const permissions = event.result.permissions;
    if (!permissions) {
      throw new Error('Expected permissions to be filled');
    }
    const cache: PageCache<PermissionDto> = { [0]: permissions };

    const groupDetails: GroupDetailsSlice = {
      ...details,
      permissionPages: {
        ...details.permissionPages,
        loading: false,
        offset: 0,
        pages: cache,
        tableCount: permissions.length,
        filter: event.text,
      },
    };
    return {
      ...state,
      users: mergeUsers(state.users, event.result.users),
      groupDetails,
    };
  }),

  on(groupApiActions.invitationLoaded, (state, { result }): GroupsState => {
    return {
      ...state,
      invitation: {
        data: result,
        sendingResponse: false,
      },
    };
  }),

  on(groupApiActions.invitationResponseUpdated, (state, { result }): GroupsState => {
    return {
      ...state,
      invitation: {
        data: result,
        sendingResponse: false,
      },
    };
  }),

  on(groupApiActions.membersAdded, (state, { groupId, result }): GroupsState => {
    const details = state.groupDetails;
    if (details?.id !== groupId) {
      return state;
    }

    const addedMembers: AddedMembersInfo = { added: 0, alreadyMember: 0 };
    for (const change of result.changes) {
      switch (change.result) {
        case 'added':
          addedMembers.added += 1;
          break;
        case 'no-change':
          addedMembers.alreadyMember += 1;
          break;
        default:
          throw new Error(`Got unexpected result when adding members: ${change.result}`);
      }
    }

    return {
      ...state,
      groupDetails: {
        ...details,
        addedMembers,
      },
    };
  }),

  on(groupApiActions.permissionsGranted, (state, { groupId, result }): GroupsState => {
    const details = state.groupDetails;
    if (details?.id !== groupId) {
      return state;
    }

    // Group results by user, to give more meaningful feedback
    const userResults: Record<string, PermissionChangeResult> = {};
    for (const change of result.changes) {
      const current = userResults[change.user_id];
      if (current === undefined || change.result === 'added') {
        userResults[change.user_id] = change.result;
      }
    }

    const grantedPermissions: GrantedPermissionsInfo = { granted: 0, alreadyGranted: 0 };
    for (const userId in userResults) {
      const result = userResults[userId];
      switch (result) {
        case 'added':
          grantedPermissions.granted += 1;
          break;
        case 'no-change':
          grantedPermissions.alreadyGranted += 1;
          break;
        default:
          throw new Error(`Got unexpected result when granting permissions: ${result}`);
      }
    }

    return {
      ...state,
      groupDetails: {
        ...details,
        grantedPermissions,
      },
    };
  }),
];

export const invitationActions = createActionGroup({
  source: 'Invitation Page',
  events: {
    Requested: (params: InvitationPageParams) => ({ params }),
    'Response Chosen': (params: InvitationPageParams, response: InvitationResponseOption) => ({ params, response }),
  },
});

const invitationReducers: GroupStateReducerTypes[] = [
  on(invitationActions.requested, (state): GroupsState => {
    // Clear a potentially old invitation entry
    return {
      ...state,
      invitation: {
        ...state.invitation,
        data: undefined,
      },
    };
  }),

  on(invitationActions.responseChosen, (state): GroupsState => {
    return {
      ...state,
      invitation: {
        ...state.invitation,
        sendingResponse: true,
      },
    };
  }),

  on(headerComponentActions.supportViewChanged, (state, { active }): GroupsState => {
    return {
      ...state,
      supportView: {
        ...state.supportView,
        active,
      },
    };
  }),
];

export const groupsFeature = createFeature({
  name: 'groups',
  reducer: createReducer(
    initialState,
    ...groupListReducers,
    ...groupApiReducers,
    ...groupDetailsReducers,
    ...editGroupReducers,
    ...memberListReducers,
    ...invitationListReducers,
    ...invitationReducers,
    ...permissionListReducers
  ),
});

export const {
  reducer,
  selectGroupsState,
  selectGroups,
  selectSupportView,
  selectLists,
  selectOpenList,
  selectListsValid,
  selectGroupDetails,
  selectUsers,
  selectInvitation,
} = groupsFeature;

{
  "addMembersDialog.addMembers": "Add Members",
  "addMembersDialog.additionalMessage": "Additional message",
  "addMembersDialog.displayName": "Display name",
  "addMembersDialog.invitationErrorMessage": "The invitation text is not allowed to exceed 2000 characters.",
  "addMembersDialog.invitationHeader": "Invitation for users without SICK ID or outside your company:",
  "addMembersDialog.nameErrorMessage": "Name must not exceed 200 characters.",
  "addMembersDialog.noUserMessage": "No user is selected.",
  "addMembersDialog.select": "Select",
  "addMembersDialog.selected": "Selected:",
  "addMembersDialog.selectedList.email": "Email Address",
  "addMembersDialog.selectedList.name": "Name",
  "addMembersDialog.typeToSearchUser": "Type an email address or search for a user",
  "addMembersDialog.willInviteUser": "The user will be invited via email",
  "addMembersFeedback.confirm": "Confirm",
  "addMembersFeedback.details.result.failure": "Failed",
  "addMembersFeedback.details.result.success": "Succeeded",
  "addMembersFeedback.details.table.alias": "Display Name",
  "addMembersFeedback.details.table.email": "Email",
  "addMembersFeedback.details.table.placeholder": "(No Display Name)",
  "addMembersFeedback.details.table.result": "Invitation",
  "addMembersFeedback.infoAddedMembers": "Added {$PH} member(s).",
  "addMembersFeedback.infoSomeMembersNotAdded": "Did not add {$PH} membe(s).",
  "addMembersFeedback.invitations": "Invitations",
  "addMembersFeedback.members": "Members",
  "addMembersFeedback.sendingInvitations": "Sending Invitations...",
  "back": "Back",
  "contactSupport.dialogHeader": "How Can We Help?",
  "contactSupport.feedback.description": "Feel free to share feedback or offer suggestions for new features. We are eager to hear your thoughts and ideas. Thank you for helping us enhance SICK ID Group Management to better meet your needs.",
  "contactSupport.feedback.name": "I want to share suggestions for improvement.",
  "contactSupport.feedback.placeholder": "Enter your feedback here.",
  "close": "Close",
  "submit": "Submit",
  "contactSupport.introduction": "If you are encountering any challenges or have questions, please specify your request type and provide further details in the text area below.",
  "contactSupport.limitExceeded": "The request text exceeds the maximum allowed length.",
  "contactSupport.orphanedGroup.description": "Please provide a brief explanation of why you require management access to the group.",
  "contactSupport.orphanedGroup.name": "I require management access to this group.",
  "contactSupport.orphanedGroup.placeholder": "State your explanation here.",
  "contactSupport.otherIssue.description": "Tell us about this issue.",
  "contactSupport.otherIssue.name": "I need assistance with a technical issue.",
  "contactSupport.otherIssue.placeholder": "Include any details we can use to help you.",
  "contactSupport.result.requestFailed": "Your request could not be sent. Please try again later.",
  "contactSupport.result.requestSucceeded": "Thank you for your request. We will do our best to assist you as quickly as possible.",
  "contactSupport.sendingRequest": "Sending your request ...",
  "contactSupport.tooltip": "Contact Support",
  "error.fallbackErrorMessage": "Oops, something went wrong. We encountered an unknown error.",
  "error.http.conflict": "The server has encountered a conflict. Perhaps an object with the same name already exists?",
  "error.http.forbidden": "You are not allowed to access this resource.",
  "error.http.invalidParameters": "The server could not handle the last request since it contained invalid parameters.",
  "error.http.maximumLengthExceeded": "The server could not process the last request because one or more inputs exceeded the manageable length.",
  "error.http.notFound": "The server could not find the resource you were looking for.",
  "error.http.unknown": "Oops, something went wrong. We had problems communicating with the server.",
  "error.http.unspecifiedBadRequest": "The server could not handle the last request due to invalid syntax.",
  "grantPermissionsDialog.grantPermissions": "Grant Permissions",
  "grantPermissionsDialog.noUserMessage": "No user is selected.",
  "grantPermissionsDialog.selected": "Selected:",
  "grantPermissionsDialog.selectedList.email": "Email Address",
  "grantPermissionsDialog.selectedList.name": "Name",
  "grantPermissionsDialog.selectedList.permission": "Permission",
  "grantPermissionsDialog.typeToSearchUser": "Type an email address or search for a user",
  "groupDetails.addMembersButton": "Add Members",
  "groupDetails.addPermissionsButton": "Grant Permissions",
  "groupDetails.details.customerName": "Customer Name",
  "groupDetails.details.deleteTooltip": "Delete Group",
  "groupDetails.details.description": "Description",
  "groupDetails.details.editTooltip": "Edit Group Properties",
  "groupDetails.details.filterPermissions": "Search Permissions",
  "groupDetails.details.hasExternalMembers": "(external members included)",
  "groupDetails.details.id": "ID",
  "groupDetails.details.memberListHidden": "Member list is hidden, since you are an external user.",
  "groupDetails.details.searchMembers": "Search Members",
  "groupDetails.details.visibility": "Visibility",
  "groupDetails.details.visibility-company-info": "Anyone in your company and anyone authorised to view and manage will be able to view this group.",
  "groupDetails.details.visibility-private-info": "This group is only visible to its members, managers, and anyone with permission to view it. Other users in your company CANNOT view this group.",
  "groupDetails.externalUser": "External User",
  "groupDetails.grantedPermissionsInfo.granted": "Granted permissions to {$PH} user(s).",
  "groupDetails.grantedPermissionsInfo.someNotGranted": "Did not grant permissions to {$PH} user(s), since they were already granted.",
  "delete": "Delete",
  "groupDetails.invitationsTable.email": "Email Address",
  "groupDetails.invitationsTable.invitedAs": "Invitation Purpose",
  "groupDetails.invitationsTable.name": "Name",
  "groupDetails.invitationsTable.response.accepted": "Accepted",
  "groupDetails.invitationsTable.response.declined": "Declined",
  "groupDetails.invitationsTable.response.pending": "Pending",
  "groupDetails.invitationsTable.sentOn": "Sent On",
  "groupDetails.invitationsTable.status": "Status",
  "groupDetails.membersTable.email": "Email Address",
  "groupDetails.membersTable.header": "Members",
  "groupDetails.membersTable.name": "Name",
  "groupDetails.membersTable.removeButtonTitle": "Remove member from group",
  "groupDetails.membersTable.removeButtonTitleDisabled": "You cannot remove unknown members",
  "groupDetails.numberOfMembers": "Number of members in this group",
  "groupDetails.permissionsTable.disabled.current": "You cannot edit your own permissions",
  "groupDetails.permissionsTable.disabled.invisible": "Permissions of unknown users cannot be edited",
  "groupDetails.permissionsTable.dropdown": "Change permission",
  "groupDetails.permissionsTable.email": "Email Address",
  "groupDetails.permissionsTable.name": "Name",
  "groupDetails.permissionsTable.permission": "Permission",
  "groupDetails.permissionsTable.revokeAllPermissions": "Revoke All Permissions",
  "groupDetails.tabs.invitations": "Invitations",
  "groupDetails.tabs.members": "Members",
  "groupDetails.tabs.permissions": "Permissions",
  "cancel": "Cancel",
  "groupList.deleteModal.deleteGroupPermanently": "Delete Group Permanently",
  "groupList.deleteModal.header": "Delete Group {$PH}",
  "groupList.deleteModal.proceedQuestion": "Are you sure, you want to proceed?",
  "groupList.deleteModal.warning": "Deleting the group is permanent and cannot be reversed.",
  "remove": "Remove",
  "groupList.detailsTooltip": "Click to see group details",
  "groupList.name": "Group Name",
  "groupList.visibility": "Visibility",
  "groupList.visibilityTooltip.company": "Anyone in your company can see this group.",
  "groupList.visibilityTooltip.private": "Only users with appropriate rights can see this group.",
  "groupListTabMenu.createGroupButton": "Create New Group",
  "groupListTabMenu.manageableGroups": "Management",
  "groupListTabMenu.myGroups": "Membership",
  "groupListTabMenu.visibleGroups": "All Visible",
  "groupPropertiesForm.createAndGoToGroupDetails": "Create Group & Go to Group Details",
  "groupPropertiesForm.createGroup": "Create Group",
  "groupPropertiesForm.customer": "Customer Name",
  "groupPropertiesForm.description": "Description",
  "groupPropertiesForm.descriptionMaxLengthError": "Description cannot be longer than {$PH} characters.",
  "groupPropertiesForm.editGroupProperties": "Edit Group Properties",
  "groupPropertiesForm.id": "ID",
  "groupPropertiesForm.name": "Group Name",
  "groupPropertiesForm.nameMaxLengthError": "Name cannot be longer than {$PH} characters.",
  "groupPropertiesForm.nameMinLengthError": "Name must be at least {$PH} characters long.",
  "groupPropertiesForm.nameNotUniqueError": "Name must be unique in your company.",
  "groupPropertiesForm.saveChanges": "Save Changes",
  "groupPropertiesForm.visibility": "Visibility",
  "header.logout": "Logout",
  "header.myAccount": "My Account",
  "invitation.acceptInvitation": "Accept Invitation",
  "invitation.alreadyAccepted.explanation": "You have already accepted the invitation to join the group '{{ group }}'.",
  "invitation.alreadyAccepted.navigateToGroup": "Go to Group Details",
  "invitation.alreadyAccepted.title": "Invitation already accepted",
  "invitation.alreadyDeclined.explanation": "You have already declined this invitation.",
  "invitation.alreadyDeclined.navigateToHome": "Go to My Groups",
  "invitation.alreadyDeclined.title": "Invitation already declined",
  "invitation.becomeManager": "become a manager of the group",
  "invitation.becomeMember": "become a member of the group",
  "invitation.becomeViewer": "be able to view the group",
  "invitation.declineInvitation": "Decline Invitation",
  "invitation.freeTextIntro": "The sender of this invitation has provided additional information:",
  "invitation.invalidLink": "The invitation link is invalid or incomplete. Make sure that you clicked the invitation link from the mail or copied it completely.",
  "invitation.joinIntro": "By accepting this invitation you will",
  "invitation.sendingMessage": "Sending response...",
  "invitation.text": "You have been invited by {$PH} ({$PH_1}) to join the group '{$PH_2}'",
  "navigationBar.applicationName": "Group Management",
  "newGroup.title": "New Group",
  "notFoundPage.goBackButton": "Go Back",
  "notFoundPage.goToHomeButton": "Go To My Groups",
  "notFoundPage.message": "Oops! We couldn't find the page you were looking for.",
  "permissionNames.manage-group": "Manage",
  "permissionNames.member": "Member",
  "permissionNames.view-group": "View",
  "routes.editGroupProperties": "Group Management | Edit Group Properties",
  "routes.fallbackTitle": "Group Management",
  "routes.invitation": "Group Management | Invitation",
  "routes.managedGroups": "Group Management | Management",
  "routes.myGroups": "Group Management | Membership",
  "routes.newGroup": "Group Management | New Group",
  "routes.visibleGroups": "Group Management | All Visible",
  "searchFieldPlaceholder": "Search Groups",
  "userCollection.deselect": "Remove user from list",
  "userSearch.timeoutError": "The user search took too long. Please modify your search text to try again.",
  "footer.imprint": "Imprint",
  "footer.termsAndConditions": "Terms and Conditions",
  "footer.termsOfUse": "Terms of Use",
  "footer.privacyPolicy": "Privacy Policy"
}

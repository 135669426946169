import { Injectable, inject } from '@angular/core';
import { GroupDetailsParameters, GroupHttpApi } from './http';
import {
  GroupDto,
  GroupInputDto,
  GroupRelation,
  GroupsOverviewParameters,
  InvitationInputListDto,
  InvitationResponseOption,
  PermissionChangeDto,
} from './dtos';
import { map } from 'rxjs/operators';
import { groupApiActions } from './actions';
import { AuthUser } from '../auth/types';
import { NavigationTarget } from '../groups/model';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';

export interface InvitationPageParams {
  groupId: string;
  invitationCode: string;
  link: string;
}

@Injectable({
  providedIn: 'root',
})
export class GroupApi {
  http = inject(GroupHttpApi);

  loadGroupsOverview(parameters: GroupsOverviewParameters, search: boolean) {
    return this.http
      .getGroupsOverview(parameters)
      .pipe(map((result) => groupApiActions.overviewLoaded(result, search)));
  }

  loadGroups(
    userData: AuthUser | undefined,
    search: string,
    offset: number,
    pageLength: number,
    relation: GroupRelation,
    invalidate: boolean
  ) {
    return this.http
      .getGroups({
        userId: userData?.id,
        search,
        offset,
        pageLength,
        relation,
      })
      .pipe(map((page) => groupApiActions.groupPageLoaded(page, offset, relation, invalidate)));
  }

  createGroup(input: GroupInputDto, target: NavigationTarget) {
    return this.http.createGroup(input).pipe(map((group) => groupApiActions.groupCreated(group.id, target)));
  }

  updateGroup(groupId: string, input: GroupInputDto) {
    return this.http.updateGroup(groupId, input).pipe(map((result) => groupApiActions.groupUpdated(result)));
  }

  deleteGroup(id: string) {
    return this.http.deleteGroup(id).pipe(map(() => groupApiActions.groupDeleted(id)));
  }

  loadGroupDetails(groupId: string, params: GroupDetailsParameters) {
    return this.http.getGroupDetails(groupId, params).pipe(map((result) => groupApiActions.groupDetailsLoaded(result)));
  }

  loadGroupIfNotPresent(groupId: string, groups: Record<string, GroupDto | undefined>) {
    const group = groups[groupId];
    if (group) {
      return of(groupApiActions.groupLoaded(group));
    }
    return this.http.getGroupById(groupId).pipe(map((result) => groupApiActions.groupLoaded(result)));
  }

  loadMembers(
    groupId: string,
    offset: number,
    pageLength: number,
    filter: '',
    invalidate?: boolean
  ): Observable<ReturnType<typeof groupApiActions.membersLoaded>>;
  loadMembers(
    groupId: string,
    offset: number,
    pageLength: number,
    filter: string,
    invalidate?: boolean
  ): Observable<ReturnType<typeof groupApiActions.membersFiltered>>;
  loadMembers(
    groupId: string,
    offset: number,
    pageLength: number,
    filter: string,
    invalidate?: boolean
  ): Observable<Action> {
    if (filter.length === 0) {
      return this.http
        .getMembers({ groupId, offset, pageLength })
        .pipe(map((page) => groupApiActions.membersLoaded(page, offset, pageLength, invalidate ?? true)));
    } else {
      return this.http
        .searchGroupUsers(groupId, 'member', filter)
        .pipe(map((result) => groupApiActions.membersFiltered(groupId, filter, result)));
    }
  }

  loadPermissions(
    groupId: string,
    offset: number,
    pageLength: number,
    filter: '',
    invalidate?: boolean
  ): Observable<ReturnType<typeof groupApiActions.permissionsLoaded>>;
  loadPermissions(
    groupId: string,
    offset: number,
    pageLength: number,
    filter: string,
    invalidate?: boolean
  ): Observable<ReturnType<typeof groupApiActions.permissionsFiltered>>;
  loadPermissions(groupId: string, offset: number, pageLength: number, filter: string, invalidate?: boolean) {
    if (filter.length === 0) {
      return this.http
        .getPermissions({ groupId, offset, pageLength })
        .pipe(map((page) => groupApiActions.permissionsLoaded(page, offset, pageLength, invalidate ?? true)));
    } else {
      return this.http
        .searchGroupUsers(groupId, 'permission', filter)
        .pipe(map((result) => groupApiActions.permissionsFiltered(groupId, filter, result)));
    }
  }

  loadInvitations(groupId: string, offset: number, pageLength: number, _filter: string, invalidate?: boolean) {
    return this.http
      .getInvitations({ groupId, offset, pageLength })
      .pipe(map((page) => groupApiActions.invitationsLoaded(page, offset, pageLength, invalidate ?? true)));
  }

  createInvitations(groupId: string, input: InvitationInputListDto) {
    if (input.invitations.length === 0) {
      return of(groupApiActions.invitationsCreated(groupId, { results: [] }));
    }
    return this.http
      .createInvitations(groupId, input)
      .pipe(map((result) => groupApiActions.invitationsCreated(groupId, result)));
  }

  addMembers(groupId: string, userIds: string[]) {
    return this.http
      .changeMembers(groupId, { add: userIds.map((id) => ({ id })), remove: [] })
      .pipe(map((result) => groupApiActions.membersAdded(groupId, result, { invitations: [] })));
  }

  addAndInviteMembers(groupId: string, addUserIds: string[], invite: InvitationInputListDto) {
    if (addUserIds.length === 0) {
      const timestamp = new Date().toISOString();
      return of(groupApiActions.membersAdded(groupId, { changes: [], timestamp }, invite));
    }
    const add = addUserIds.map((id) => ({ id }));
    return this.http
      .changeMembers(groupId, { add, remove: [] })
      .pipe(map((result) => groupApiActions.membersAdded(groupId, result, invite)));
  }

  removeMembers(groupId: string, userIds: string[]) {
    return this.http
      .changeMembers(groupId, { add: [], remove: userIds.map((id) => ({ id })) })
      .pipe(map((result) => groupApiActions.membersRemoved(groupId, result)));
  }

  changePermissions(groupId: string, change: PermissionChangeDto, grant?: boolean) {
    return this.http.changePermissions(groupId, change).pipe(
      map((result) => {
        if (grant) {
          return groupApiActions.permissionsGranted(groupId, result);
        }
        return groupApiActions.permissionsChanged(groupId, result);
      })
    );
  }

  deleteInvitation(groupId: string, code: string) {
    return this.http.deleteInvitation(groupId, code).pipe(map(() => groupApiActions.invitationDeleted(groupId, code)));
  }

  getInvitation(params: InvitationPageParams) {
    return this.http
      .getInvitation(params.groupId, params.invitationCode, params.link)
      .pipe(map((result) => groupApiActions.invitationLoaded(result)));
  }

  respondToInvitation(params: InvitationPageParams, response: InvitationResponseOption) {
    return this.http
      .postInvitationResponse(params.groupId, params.invitationCode, response)
      .pipe(map((result) => groupApiActions.invitationResponseUpdated(result)));
  }
}

import { APP_INITIALIZER } from '@angular/core';
import { Store } from '@ngrx/store';
import { initAuthentication } from './auth/auth';

export const authInitializer = {
  provide: APP_INITIALIZER,
  multi: true,
  useFactory: (store: Store) => () => initAuthentication(store),
  deps: [Store],
};

export const appInitializers = [authInitializer];

import { Environment } from './types';

const apiUrl = 'https://groups.test-cloud.sick.com/api';

export const environment: Environment = {
  production: false,
  auth: {
    url: 'https://id.test.sick.com/auth',
    realm: 'sickservices',
    clientId: 'group-management-frontend',
  },
  apiUrl,
  frontendUrl: 'https://groups.test-cloud.sick.com',
};

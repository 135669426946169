import { inject, Injectable } from '@angular/core';
import { createSelector, Store } from '@ngrx/store';
import { debounceTime, distinctUntilChanged, map, Observable, Subscription } from 'rxjs';
import { groupListActions, selectGroups, selectLists, selectOpenList } from '../store/groups.feature';
import { DeleteGroupEvent, PageChangeEvent } from './group-list.component';
import { GroupDto, GroupRelation } from '../../api/dtos';

const searchFieldDebounceTimeInMs = 500;

export interface TotalCounts {
  manager: number;
  member: number;
  visible: number;
}

export interface GroupListViewModel {
  groups: GroupDto[];
  totalCounts: TotalCounts;
  offsets: TotalCounts;
  pageLength: number;
  pageLoaded: boolean;
}

const selectViewModel = createSelector(
  selectGroups,
  selectLists,
  selectOpenList,
  (groupsMap, lists, openList): GroupListViewModel => {
    const list = lists[openList];
    const page = list.pages?.[list.offset];
    const groups = page?.map((id) => groupsMap[id]);

    return {
      groups,
      totalCounts: {
        manager: lists['manager'].totalCount,
        member: lists['member'].totalCount,
        visible: lists['visible'].totalCount,
      },
      offsets: {
        manager: lists['manager'].offset,
        member: lists['member'].offset,
        visible: lists['visible'].offset,
      },
      pageLength: list.pageLength,
      pageLoaded: page !== undefined,
    };
  }
);

@Injectable({
  providedIn: 'root',
})
export class GroupListService {
  private store = inject(Store);
  readonly viewModel$ = this.store.select(selectViewModel);
  private subscription?: Subscription;
  private firstOpen = true;

  open(listType: GroupRelation) {
    this.store.dispatch(groupListActions.opened(listType, this.firstOpen));
    this.firstOpen = false;
  }

  subscribe(searchQueries: Observable<string | null>) {
    this.subscribeToSearchQueries(searchQueries);
  }

  unsubscribe() {
    this.subscription?.unsubscribe();
  }

  private subscribeToSearchQueries(searchQueries: Observable<string | null>) {
    this.subscription = searchQueries
      .pipe(debounceTime(searchFieldDebounceTimeInMs), distinctUntilChanged())
      .pipe(map((searchText) => searchText ?? ''))
      .subscribe((searchText) => this.store.dispatch(groupListActions.searchQueryChanged(searchText)));
  }

  onPageChange(event: PageChangeEvent) {
    if (event.offset !== undefined) {
      this.store.dispatch(groupListActions.offsetChanged(event.offset));
    }
  }

  onDelete(event: DeleteGroupEvent) {
    this.store.dispatch(groupListActions.deletionRequested(event.groupId));
  }
}

import { Component } from '@angular/core';

// account-question.svg:
// - Made by Carine Flamm
// - Similar to Material Design: account-question

@Component({
  selector: 'app-icon-account-question',
  template: `<svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 24 24">
    <path
      d="M13,8A4,4 0 0,1 9,12A4,4 0 0,1 5,8A4,4 0 0,1 9,4A4,4 0 0,1 13,8M17,18V20H1V18C1,15.79 4.58,14 9,14C13.42,14 17,15.79 17,18M20.5,14.5V16H19V14.5H20.5M18.5,9.5H17V9A3,3 0 0,1 20,6A3,3 0 0,1 23,9C23,9.97 22.5,10.88 21.71,11.41L21.41,11.6C20.84,12 20.5,12.61 20.5,13.3V13.5H19V13.3C19,12.11 19.6,11 20.59,10.35L20.88,10.16C21.27,9.9 21.5,9.47 21.5,9A1.5,1.5 0 0,0 20,7.5A1.5,1.5 0 0,0 18.5,9V9.5Z"
    />
  </svg>`,
  styles: [
    `
      svg {
        fill: var(--syn-color-neutral-950);
      }
    `,
  ],
  standalone: true,
})
export class IconAccountQuestion {}

// These icons are stolen from sick.com

export const ICON_WORLD = `<svg
viewBox="0 0 28 28"
xmlns="http://www.w3.org/2000/svg"
>
<g
  stroke="none"
  stroke-width="1"
  fill="none"
  fill-rule="evenodd"
>
  <g
    transform="translate(-50.000000, -2.000000)"
  >
    <g
      transform="translate(48.000000, 0.000000)"
    >
      <polygon id="Shape" points="0 0 32 0 32 32 0 32"></polygon>
      <path
        d="M16,2.66666667 C8.63644444,2.66666667
2.66666667,8.63644444 2.66666667,16 L2.66711111,16.0235556 L2.66666667,16.0471111 C2.66666667,23.3848889
8.60311111,29.3333333 15.9266667,29.3333333 L15.9551111,29.3324444 L16,29.3333333 C23.3635556,29.3333333
29.3333333,23.3635556 29.3333333,16 C29.3333333,8.63644444 23.3635556,2.66666667 16,2.66666667 M28.4444444,16
C28.4444444,17.064 28.3102222,18.0964444 28.0595556,19.0817778 C27.9435556,19.0017778 27.6368889,19.3324444
27.5613333,18.8942222 C27.4666667,18.3502222 26.8533333,17.9724444 26.5231111,18.2324444
C26.1928889,18.4924444 25.2493333,19.2484444 25.2026667,19.7213333 C25.1551111,20.1937778
24.7537778,20.7608889 24.5657778,20.36 C24.3764444,19.9582222 23.8111111,18.7053333 23.8342222,18.3746667
C23.8573333,18.044 23.5737778,18.4928889 23.1506667,17.8311111 C22.7257778,17.1693333
21.9946667,17.736 21.4048889,17.4053333 C20.8151111,17.0742222 20.1546667,16.6253333 19.824,16.484
C19.4946667,16.3417778 20.4137778,17.8071111 20.532,17.6182222 C20.6506667,17.4293333 21.1217778,17.76
21.2635556,17.76 C21.4053333,17.76 21.7822222,18.0906667 21.5466667,18.3982222 C21.3111111,18.7057778
19.212,19.9831111 18.9995556,19.6506667 C18.7862222,19.3182222 17.5373333,16.7435556 17.3244444,16.9013333
C17.112,17.0591111 18.6457778,19.7448889 18.9053333,19.9102222 C19.164,20.076 19.9431111,19.9342222
20.32,19.7688889 C20.6977778,19.6035556 19.5417778,21.6124444 19.0471111,21.8488889 C18.552,22.0848889
18.1511111,22.8177778 18.3626667,23.1484444 C18.5751111,23.4791111 18.5986667,24.732 18.1511111,24.8977778
C17.7022222,25.0626667 17.5373333,25.3226667 17.6786667,25.6302222 C17.8195556,25.9368889
17.3488889,26.4564444 17.1364444,26.7875556 C16.9235556,27.1182222 16.0986667,27.8035556
16.0275556,27.9928889 C15.9573333,28.1822222 14.9431111,28.1346667 14.5413333,28.1346667
C14.1408889,28.1346667 14.4471111,27.6382222 14.3995556,27.2133333 C14.3533333,26.7875556
13.9995556,26.3857778 13.9995556,26.1733333 C13.9995556,25.9604444 13.8808889,25.464 13.7164444,25.228
C13.5515556,24.9911111 13.5035556,24.5422222 13.6688889,24.2826667 C13.8346667,24.0222222 13.952,23.2666667
13.6226667,22.9355556 C13.292,22.6048889 12.9142222,22.0373333 13.1742222,21.5884444 C13.4337778,21.1395556
12.8911111,21.1871111 12.4431111,20.9737778 C11.9946667,20.7608889 11.0746667,21.0448889 10.6031111,21.116
C10.1311111,21.1866667 9.66,20.9977778 9.51822222,20.7608889 C9.37688889,20.5248889 9.07022222,19.9342222
8.756,19.792 C8.44266667,19.6506667 8.62222222,19.036 8.81066667,18.8 C9,18.564 8.62222222,18.1146667
8.81066667,17.9248889 C9,17.736 9.11733333,17.0511111 9.49466667,16.9013333 C9.87244444,16.752
9.80133333,16.6493333 9.81288889,16.2946667 C9.82488889,15.94 10.2373333,15.94 10.3075556,15.7511111
C10.3786667,15.5617778 10.5906667,15.4675556 11.0164444,15.4675556 C11.4408889,15.4675556
12.1595556,15.2191111 12.5017778,15.1364444 C12.844,15.0537778 13.4337778,14.9355556 13.3862222,15.2666667
C13.3391111,15.5973333 13.3511111,15.9751111 13.5746667,15.9751111 C13.7991111,15.9751111
14.1057778,16.1053333 14.4595556,16.3257778 C14.8133333,16.5457778 14.8248889,16.3657778
14.8724444,16.1173333 C14.92,15.8688889 15.4146667,15.9635556 15.9266667,16.2 C16.4386667,16.4364444
17.1368889,16.2706667 17.36,16.2475556 C17.5844444,16.2231111 17.6791111,15.5502222 17.62,15.3257778
C17.5604444,15.1013333 17.2071111,15.432 16.9831111,15.2902222 C16.7591111,15.1488889 16.452,15.3257778
16.2284444,15.1244444 C16.004,14.924 15.9684444,14.4511111 16.2284444,14.4395556 C16.4875556,14.4275556
16.6177778,14.3448889 16.7706667,14.168 C16.9244444,13.9906667 17.5022222,13.8844444 17.7262222,14.0613333
C17.9497778,14.2386667 18.2808889,14.3568889 18.4804444,14.156 C18.6808889,13.9555556 18.0804444,13.5657778
17.9497778,13.4591111 C17.82,13.3528889 17.9262222,13.0102222 17.7968889,13.0102222 C17.6666667,13.0102222
17.5604444,13.0688889 17.6315556,13.2346667 C17.7022222,13.4 17.2542222,13.6951111 17.1826667,13.5302222
C17.1124444,13.3644444 16.9946667,13.0457778 16.8177778,13.0457778 C16.6413333,13.0457778
16.4053333,13.8493333 16.3933333,13.9791111 C16.3813333,14.1093333 16.0746667,14.3217778 15.8275556,14.3217778
C15.5804444,14.3217778 15.5804444,14.6057778 15.7097778,14.736 C15.8395556,14.8657778 15.7448889,15.3857778
15.5093333,15.4155556 C15.2733333,15.4457778 15.1906667,15.3622222 15.1555556,15.1608889
C15.1204444,14.9604444 14.8844444,14.984 14.8724444,14.5702222 C14.8604444,14.1564444 14.6008889,13.956
14.436,13.7666667 C14.2711111,13.5773333 13.8462222,13.0222222 13.6813333,13.2706667 C13.5164444,13.5191111
14.2711111,14.0506667 14.4711111,14.192 C14.6724444,14.3337778 14.6368889,14.6408889 14.5306667,14.712
C14.4244444,14.7831111 14.224,15.3622222 14.1062222,15.4088889 C13.9875556,15.456 13.8751111,15.2328889
13.8751111,15.2328889 C13.6982222,15.1622222 14.1222222,14.9493333 14.1933333,14.8431111
C14.2635556,14.7368889 13.4506667,13.9924444 13.304,13.7795556 C13.1573333,13.5666667 12.5306667,13.9564444
12.248,13.8151111 C11.9648889,13.6737778 11.54,14.3466667 11.5053333,14.7364444 C11.4693333,15.1266667
11.0093333,15.2324444 10.5848889,15.2324444 C10.1608889,15.2324444 9.87733333,14.9137778
10.0195556,14.5946667 C10.1608889,14.2755556 9.94844444,13.744 10.1964444,13.7084444 C10.444,13.6733333
11.1506667,13.7084444 11.2924444,13.3186667 C11.4337778,12.9288889 10.62,12.5742222 10.9391111,12.5035556
C11.2568889,12.4324444 11.7528889,11.9368889 12.1066667,11.6528889 C12.4595556,11.3693333
12.4951111,11.0151111 12.8133333,10.9902222 C13.1324444,10.9657778 12.7431111,10.3764444 13.0262222,10.1995556
C13.3097778,10.0222222 13.38,10.8377778 13.5924444,10.8377778 C13.8048889,10.8377778 14.2284444,10.7666667
14.5826667,10.944 C14.9368889,11.1213333 15.0426667,10.7666667 15.1844444,10.3053333 C15.3257778,9.84488889
15.6795556,9.59644444 16.3866667,9.24222222 C17.0942222,8.88755556 15.5737778,9.34844444 15.2902222,8.99377778
C15.0071111,8.63911111 15.3964444,7.85911111 15.5737778,7.30844444 C15.7506667,6.75733333
15.0075556,7.85911111 14.6182222,8.42666667 C14.2284444,8.99377778 14.7244444,9.24177778 14.6884444,9.63155556
C14.6533333,10.0217778 14.0173333,10.4822222 14.0173333,10.4822222 C13.7342222,10.5177778 13.7693333,9.95066667
13.628,9.70266667 C13.4862222,9.45466667 13.2382222,9.56088889 12.8488889,9.84444444 C12.4595556,10.128
12.4244444,9.17111111 12.3186667,8.78088889 C12.2133333,8.39155556 12.9555556,7.89466667 13.3044444,7.62977778
C13.6528889,7.364 14.2284444,6.08711111 14.8657778,5.09511111 C15.5031111,4.10266667 16.4226667,4.59866667
16.6702222,4.98888889 C16.9186667,5.37822222 17.5542222,5.13022222 18.1564444,5.83955556 C18.7582222,6.54844444
18.0146667,6.86711111 17.3435556,6.57911111 C16.6702222,6.29111111 17.4844444,7.29288889 17.8728889,7.57644444
C18.2622222,7.85955556 18.4746667,6.93822222 18.6164444,6.72577778 C18.7577778,6.51288889 19.288,6.61955556
19.6768889,6.052 C20.0666667,5.48488889 20.9506667,5.59155556 21.4555556,5.65377778 C21.9608889,5.71644444
21.8351111,5.34311111 21.5168889,5.03777778 C21.2813333,4.812 21.7631111,5.00977778 22.3302222,5.28666667
C25.9893333,7.45244444 28.4444444,11.4386667 28.4444444,16 M10.6853333,11.7653333 C10.568,11.644
10.804,11.1662222 10.9097778,11.0244444 C11.0164444,10.8826667 10.6146667,10.9297778 10.6391111,11.1311111
C10.6626667,11.3315556 10.4853333,11.6391111 10.2377778,11.7217778 C9.99022222,11.8044444 9.708,11.7804444
9.84888889,11.556 C9.98977778,11.3315556 9.86044444,11.1306667 9.75422222,10.9177778 C9.64844444,10.7048889
10.3435556,10.5751111 10.4857778,10.4453333 C10.6271111,10.3155556 10.2622222,10.2324444 10.2733333,10.032
C10.2853333,9.83066667 10.368,9.65377778 10.5084444,9.63022222 C10.6502222,9.60666667 10.7826667,9.516
10.9097778,9.524 C11.2995556,9.548 11.1804444,10.4337778 11.3226667,10.7177778 C11.464,11.0008889
11.8653333,11.344 11.8297778,11.5915556 C11.7942222,11.84 11.7186667,11.9817778 11.4297778,11.9106667
C11.1395556,11.8395556 10.6751111,12.2768889 10.4973333,12.1942222 C10.32,12.1111111 10.804,11.8866667
10.6853333,11.7653333 M8.144,6.796 C8.49822222,6.97288889 8.78044444,6.652 9.02755556,6.58311111
C9.32044444,6.50222222 9.48755556,7.54044444 9.20488889,7.50488889 C8.92177778,7.46933333 8.60355556,8.072
8.19466667,8.03644444 C7.78533333,8.00088889 7.96666667,7.71777778 7.50666667,7.29244444 C7.408,7.20088889
7.36755556,7.11822222 7.364,7.04577778 C7.46222222,6.95111111 7.56,6.856 7.66177778,6.76488889
C7.82088889,6.72266667 8.00977778,6.72888889 8.144,6.796 M19.2115556,24.6364444 C19.5417778,24.5657778
19.4782222,23.9373333 19.7777778,23.904 C20.4146667,23.8333333 19.8013333,25.6053333 19.5657778,26.1017778
C19.3297778,26.5982222 19.2346667,26.5742222 19.0102222,26.2671111 C18.7848889,25.9604444
18.8577778,25.4635556 19.0102222,25.2275556 C19.1622222,24.9906667 18.8808889,24.7075556
19.2115556,24.6364444"
        fill="#737F85"
      ></path>
    </g>
  </g>
</g>
</svg>`;

export const ICON_PERSON = `<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M9 14.2207C11.67 14.2207 17 15.5607 17 18.2207V20.2207H1V18.2207C1 15.5607 6.33 14.2207 9 14.2207ZM21.9565 8.2207L23 9.27953L18.1374 14.2207L15 11.0442L16.0435 9.98541L18.1374 12.1031L21.9565 8.2207ZM9 4.2207C11.21 4.2207 13 6.0107 13 8.2207C13 10.4307 11.21 12.2207 9 12.2207C6.79 12.2207 5 10.4307 5 8.2207C5 6.0107 6.79 4.2207 9 4.2207Z" fill="#737F85"/>
</svg>`;

<ng-container>
  @if (viewModel$ | async; as viewModel) {
    <div class="margin-1">
      <app-back-link></app-back-link>
      <app-group-details-properties
        [name]="viewModel.group.display_name"
        [description]="viewModel.group.description"
        [visibility]="viewModel.group.visibility"
        [customerName]="viewModel.group.customer_name"
        [id]="viewModel.group.id"
        [readonly]="false"
      ></app-group-details-properties>
      <div class="tab-section">
        <syn-tab-group>
          <syn-tab slot="nav" panel="members" active="">
            <span i18n="@@groupDetails.tabs.members">Members</span>
            <app-pill class="tab-menu-pill">{{ viewModel.members.totalCount }}</app-pill>
          </syn-tab>
          <syn-tab-panel name="members">
            <div class="flex-row col-gap-1 margin-bottom-1 margin-top-1">
              <app-search-field
                id="filterMembers"
                i18n-placeholder="@@groupDetails.details.searchMembers"
                placeholder="Search Members"
                (textChange)="onFilterMembers($event)"
                class="flex-grow-1"
                #memberSearchField
              ></app-search-field>
              <app-button
                id="members-open"
                icon="plus"
                (submit)="openAddMembersDialog()"
                i18n="@@groupDetails.addMembersButton"
              >
                Add Members
              </app-button>
            </div>
            <app-add-members (submit)="onAddOrInviteMembers($event)"> </app-add-members>
            <app-add-members-feedback
              [hasInvitedUsers]="hasInvitedUsers"
              (close)="onAddMembersResultsConfirmed()"
            ></app-add-members-feedback>
            <app-member-list
              [members]="viewModel.members.entries"
              [showMemberDetails]="true"
              [showRemoveButton]="true"
              [rowCount]="viewModel.members.pageLength"
              [totalCount]="viewModel.members.tableCount"
              [loading]="viewModel.members.loading"
              (pageChange)="onMemberPageChange($event)"
              (removeMember)="onRemoveMember($event.id)"
            >
            </app-member-list>
          </syn-tab-panel>

          <syn-tab slot="nav" panel="permissions">
            <span i18n="@@groupDetails.tabs.permissions">Permissions</span>
            <app-pill class="tab-menu-pill">{{ viewModel.permissions.totalCount }}</app-pill>
          </syn-tab>
          <syn-tab-panel name="permissions">
            <div class="flex-row col-gap-1 margin-bottom-1 margin-top-1">
              <app-search-field
                id="filterPermissions"
                i18n-placeholder="@@groupDetails.details.filterPermissions"
                placeholder="Search Permissions"
                (textChange)="onFilterPermissions($event)"
                class="flex-grow-1"
                #permissionSearchField
              ></app-search-field>
              <app-button
                id="permissions-open"
                icon="plus"
                (submit)="openGrantPermissionsDialog()"
                i18n="@@groupDetails.addPermissionsButton"
                >Grant Permissions</app-button
              >
            </div>
            <div class="notification">
              <app-inline-notification
                [active]="viewModel.grantedPermissions !== undefined"
                [attr.variant]="'success'"
                (onClose)="onRemoveGrantedPermissionsInfo()"
              >
                {{ getGrantedPermissionsInfo(viewModel.grantedPermissions) }}
              </app-inline-notification>
            </div>
            <app-grant-permissions (submit)="onGrantPermissions($event)"></app-grant-permissions>
            <app-permission-list
              [permissions]="viewModel.permissions.entries"
              [rowCount]="viewModel.permissions.pageLength"
              [totalCount]="viewModel.permissions.tableCount"
              [loading]="viewModel.permissions.loading"
              (pageChange)="onPermissionPageChange($event)"
              (changePermission)="onChangePermission($event)"
            >
            </app-permission-list>
          </syn-tab-panel>

          <syn-tab slot="nav" panel="invitations">
            <span i18n="@@groupDetails.tabs.invitations">Invitations</span>
            <app-pill class="tab-menu-pill">{{ viewModel.invitations.totalCount }}</app-pill>
          </syn-tab>
          <syn-tab-panel name="invitations">
            <div class="margin-top-1">
              <app-invitation-list
                [invitations]="viewModel.invitations.entries"
                [rowCount]="viewModel.invitations.pageLength"
                [totalCount]="viewModel.invitations.totalCount"
                [loading]="viewModel.invitations.loading"
                (pageChange)="onInvitationPageChange($event)"
                (removeInvitation)="onRemoveInvitation($event.code)"
              >
              </app-invitation-list>
            </div>
          </syn-tab-panel>
        </syn-tab-group>
      </div>
    </div>
  }
</ng-container>

import { CUSTOM_ELEMENTS_SCHEMA, Component, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { sharedSelectors } from '../../store/shared.feature';
import { animate, style, transition, trigger } from '@angular/animations';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-error-banner',
  template: `@if (openError$ | async; as openError) {
    <syn-alert
      @transitionTrigger
      variant="danger"
      [attr.open]="openError ? true : null"
      data-testid="app-error-banner-callout"
    >
      <syn-icon slot="icon" name="error"></syn-icon>
      {{ openError.errorMessageKey }}
    </syn-alert>
  }`,
  styles: [],
  animations: [
    trigger('transitionTrigger', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scaleY(0)', transformOrigin: '50% 0' }),
        animate('300ms ease-in-out', style({ opacity: 1, transform: 'scaleY(1)' })),
      ]),
      transition(':leave', [animate('500ms', style({ opacity: 0 }))]),
    ]),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  standalone: true,
  imports: [AsyncPipe],
})
export class ErrorBannerComponent {
  private store = inject(Store);
  readonly openError$ = this.store.select(sharedSelectors.selectOpenError);
}

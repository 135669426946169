export const SUPPORT_REQUEST_TYPES = ['orphaned-group', 'feedback', 'other-issue'] as const;
export type SupportRequestType = (typeof SUPPORT_REQUEST_TYPES)[number];

export function isSupportRequestType(value: unknown): value is SupportRequestType {
  if (typeof value !== 'string') {
    return false;
  }

  return SUPPORT_REQUEST_TYPES.includes(value as SupportRequestType);
}

export function processSupportRequestText(type: SupportRequestType, rawText: string): string {
  switch (type) {
    case 'orphaned-group':
      return `
The user requests management access to this group. 

Reason: 
${rawText}
      `;
    default:
      return rawText;
  }
}

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, combineLatest, first, mergeMap } from 'rxjs';
import { selectSupportView } from 'src/app/groups/store/groups.feature';
import { environment } from '../../environments/environment';
import { getAccessToken } from './auth';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private support = inject(Store).select(selectSupportView);

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const merged = combineLatest([this.support, getAccessToken()]);
    return merged.pipe(
      first(),
      mergeMap(([support, token]) => {
        if (req.url.startsWith(environment.apiUrl)) {
          const params = support.active ? req.params.append('support', true) : req.params;
          const headers = req.headers.append('Authorization', 'Bearer ' + token);
          req = req.clone({ params, headers });
        }
        return next.handle(req);
      })
    );
  }
}

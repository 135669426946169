import { InjectionToken } from '@angular/core';
import { ErrorType } from '../global-error-handler';

export interface ErrorListener {
  handleError: (error: ErrorType) => void;
  setNextListener: (listener: ErrorListener) => ErrorListener;
}

export const ERROR_LISTENERS = new InjectionToken<ErrorListener>('ERROR_LISTENERS');

export abstract class AbstractErrorListener implements ErrorListener {
  private nextListener?: ErrorListener;

  setNextListener(listener: ErrorListener): ErrorListener {
    this.nextListener = listener;
    return listener;
  }

  handleError(error: ErrorType) {
    if (this.nextListener) {
      return this.nextListener.handleError(error);
    }

    return null;
  }
}

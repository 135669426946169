import { CUSTOM_ELEMENTS_SCHEMA, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

type OnChangeFn = (value: boolean) => unknown;
type OnTouchedFn = () => unknown;

@Component({
  selector: 'app-toggle-switch',
  template: `
    <div class="flex-row col-gap-1">
      <syn-switch
        [attr.disabled]="disabled ? true : null"
        [attr.checked]="checked ? true : null"
        size="large"
        (syn-change)="onInput($event)"
        (syn-blur)="onBlur($event)"
        #element
      >
      </syn-switch>
      @if (iconOn) {
        <syn-icon style="font-size: var(--syn-font-size-large);" [attr.name]="checked ? iconOn : iconOff"> </syn-icon>
      }
    </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ToggleSwitchComponent,
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  standalone: true,
})
export class ToggleSwitchComponent implements ControlValueAccessor {
  @Input() disabled = false;
  @Input() checked = false;
  @Input() invalid = false;
  @Input() iconOn = '';
  @Input() iconOff = '';

  @Output() change = new EventEmitter<boolean>();

  @ViewChild('element') element: ElementRef<HTMLInputElement> | undefined;

  private touched = false;
  private onChange: OnChangeFn = () => {};
  private onTouched: OnTouchedFn = () => {};

  writeValue(value: boolean): void {
    this.checked = value;
  }
  registerOnChange(onChange: OnChangeFn): void {
    this.onChange = onChange;
  }
  registerOnTouched(onTouched: OnTouchedFn): void {
    this.onTouched = onTouched;
  }
  setDisabledState?(disabled: boolean): void {
    this.disabled = disabled;
  }

  onInput(event: Event) {
    if (event.target === this.element?.nativeElement) {
      this.checked = (event.target as HTMLInputElement).checked;
      this.onChange(this.checked);

      this.change.emit(this.checked);
    }
  }

  onBlur(event: Event) {
    if (event.target === this.element?.nativeElement) {
      if (this.touched) return;
      this.onTouched();
      this.touched = true;
    }
  }
}

<p-table
  [paginator]="showPagination"
  [lazy]="true"
  [value]="values"
  [rows]="rowsPerPage"
  [totalRecords]="totalValuesCount"
  [loading]="loading"
  [lazyLoadOnInit]="false"
  [showCurrentPageReport]="true"
  [showPageLinks]="false"
  [scrollable]="true"
  [scrollHeight]="scrollHeight"
  styleClass="app-table p-datatable-striped p-datatable-sm"
  responsiveLayout="scroll"
  currentPageReportTemplate="{first}-{last} of {totalRecords}"
  (onLazyLoad)="lazyLoadEmitter.emit($event)"
  [selectionMode]="selectionMode"
  [(first)]="this.first"
  >
  @if (headerTemplate) {
    <ng-template pTemplate="header">
      <ng-container [ngTemplateOutlet]="headerTemplate"></ng-container>
    </ng-template>
  }
  @if (bodyTemplate) {
    <ng-template pTemplate="body" let-value let-rowIndex="rowIndex" let-columns="columns">
      <ng-container
        [ngTemplateOutlet]="bodyTemplate"
        [ngTemplateOutletContext]="{ $implicit: value, rowIndex, columns }"
      ></ng-container>
    </ng-template>
  }
  @if (footerTemplate) {
    <ng-template pTemplate="footer">
      <ng-container [ngTemplateOutlet]="footerTemplate"></ng-container>
    </ng-template>
  }
</p-table>

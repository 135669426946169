<app-table
  [values]="members"
  [rowsPerPage]="rowCount"
  [totalValuesCount]="totalCount"
  [loading]="loading"
  (onLazyLoad)="onLazyLoad($event)"
>
  <ng-template tableTemplate="header">
    <tr>
      <th i18n="@@groupDetails.membersTable.name">Name</th>
      <th i18n="@@groupDetails.membersTable.email">Email</th>
      @if (showRemoveButton) {
        <th style="width: 6rem" i18n="@@remove">Remove</th>
      }
    </tr>
  </ng-template>
  <ng-template tableTemplate="body" let-member>
    <tr>
      @if (member.name) {
        <td>
          {{ member.name + (member.username ? ' (' + member.username + ')' : '') }}
        </td>
      } @else {
        <td>
          <p class="external-user" i18n="@@groupDetails.externalUser">External User</p>
        </td>
      }
      <ng-template #externalUser>
        <td>
          <p class="external-user" i18n="@@groupDetails.externalUser">External User</p>
        </td>
      </ng-template>
      <td>{{ member.email ?? '-' }}</td>
      @if (showRemoveButton) {
        <td class="td-center">
          <syn-icon-button
            name="delete"
            size="medium"
            data-testid="delete"
            [title]="getRemoveButtonTitle(member)"
            [disabled]="!isMemberEditable(member)"
            i18n-label="@@remove"
            label="Remove"
            (click)="onRemoveMember(member)"
          ></syn-icon-button>
        </td>
      }
    </tr>
  </ng-template>
</app-table>

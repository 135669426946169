import { Route } from '@angular/router';
import { $localize } from '@angular/localize/init';
import { getRouterSelectors } from '@ngrx/router-store';
import { EditGroupPropertiesComponent } from './groups/edit-group-properties/edit-group-properties.component';
import { EditGroupComponent } from './groups/edit-group/edit-group.component';
import { GroupDetailsComponent } from './groups/group-details/group-details.component';
import { InvitationComponent } from './groups/invitation/invitation.component';
import { NewGroupComponent } from './groups/new-group/new-group.component';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { GroupsOverviewComponent } from './groups/groups-overview/groups-overview.component';

export const routes: Route[] = [
  { path: '', redirectTo: 'managed-groups', pathMatch: 'full' },
  {
    path: 'my-groups',
    title: $localize`:@@routes.myGroups:Group Management | Membership`,
    children: [
      { path: '', component: GroupsOverviewComponent },
      {
        path: ':id',
        component: GroupDetailsComponent,
      },
    ],
  },
  {
    path: 'managed-groups',
    title: $localize`:@@routes.managedGroups:Group Management | Management`,
    children: [
      { path: '', component: GroupsOverviewComponent },
      {
        path: ':id',
        children: [
          { path: '', component: EditGroupComponent },
          {
            path: 'edit',
            title: $localize`:@@routes.editGroupProperties:Group Management | Edit Group Properties`,
            component: EditGroupPropertiesComponent,
          },
        ],
      },
    ],
  },
  {
    path: 'visible-groups',
    title: $localize`:@@routes.visibleGroups:Group Management | All Visible`,
    children: [
      { path: '', component: GroupsOverviewComponent },
      {
        path: ':id',
        component: GroupDetailsComponent,
      },
    ],
  },
  {
    path: 'new',
    title: $localize`:@@routes.newGroup:Group Management | New Group`,
    component: NewGroupComponent,
  },
  {
    path: 'invitation',
    title: $localize`:@@routes.invitation:Group Management | Invitation`,
    component: InvitationComponent,
  },
  { path: '**', component: PageNotFoundComponent },
];

export const {
  selectCurrentRoute,
  selectFragment,
  selectQueryParams,
  selectQueryParam,
  selectRouteParams,
  selectRouteParam,
  selectRouteData,
  selectUrl,
  selectTitle,
} = getRouterSelectors();

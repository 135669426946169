<app-table
  [values]="invitations"
  [rowsPerPage]="rowCount"
  [totalValuesCount]="totalCount"
  [loading]="loading"
  (onLazyLoad)="onLazyLoad($event)"
>
  <ng-template tableTemplate="header">
    <tr>
      <th i18n="@@groupDetails.invitationsTable.email">Email Address</th>
      <th i18n="@@groupDetails.invitationsTable.name">Name</th>
      <th i18n="@@groupDetails.invitationsTable.invitedAs">Invitation Purpose</th>
      <th i18n="@@groupDetails.invitationsTable.sentOn">Sent On</th>
      <th i18n="@@groupDetails.invitationsTable.status">Status</th>
      <th style="width: 6rem" i18n="@@delete">Delete</th>
    </tr>
  </ng-template>
  <ng-template tableTemplate="body" let-inv>
    <tr>
      <td>{{ inv.email }}</td>
      <td>{{ inv.display_name }}</td>
      <td>
        {{ translateTypes(inv.types) }}
      </td>
      <td>{{ inv.sent_timestamp | date: 'medium' }}</td>
      <!-- t(groupDetails.invitationsTable.response.accepted, groupDetails.invitationsTable.response.declined, groupDetails.invitationsTable.response.pending)-->
      <td>
        <div class="flex-row justify-start" style="gap: 0.5rem">
          @if (inv.response === 'accepted') {
            <app-icon icon="check"></app-icon>
          }
          @if (inv.response === 'declined') {
            <app-icon icon="close"></app-icon>
          }
          @if (inv.response === 'pending') {
            <app-icon icon="pending"></app-icon>
          }
          {{ translateResponse(inv.response) }}
        </div>
      </td>
      <td class="td-center">
        <syn-icon-button
          name="delete"
          size="medium"
          data-testid="delete"
          title="Delete"
          i18n-title="@@delete"
          i18n-label="@@delete"
          label="Delete"
          (click)="onRemoveInvitation(inv)"
        ></syn-icon-button>
      </td>
    </tr>
  </ng-template>
</app-table>
